// @ts-ignore
import { loadAsyncComponents } from '@akryum/vue-cli-plugin-ssr/client';

import { createApp } from './main';
import Vue from 'vue';
import VueGtag from 'vue-gtag';
import VueGTM from '@gtm-support/vue2-gtm';
import FacebookPixel from './plugins/facebook-pixel';
import GAuth from 'vue-google-oauth2';
import Toast from 'vue-toastification';
// @ts-ignore
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import VueCookies from 'vue-cookies';
import VueObserveVisibility from 'vue-observe-visibility';
import Buefy from 'buefy';
// import 'buefy/dist/buefy.css'
import VModal from 'vue-js-modal';
import PortalVue from 'portal-vue';

createApp({
  mode: 'client',
  serverConfig: undefined,
  async beforeApp({ router, config }) {
    // Use analytic software only at production
    if (config.environment === 'production') {
      Vue.use(
        VueGtag,
        {
          config: { id: 'G-B6G5CXCD95' },
          includes: [{ id: 'UA-185354641-1' }]
        },
        router
      );
      Vue.use(VueGTM, {
        id: 'GTM-MT624MJ',
        vueRouter: router
      });
    }

    Vue.use(FacebookPixel, {
      router
    });

    Vue.use(Toast, {
      maxToasts: 3,
      newestOnTop: true,
      position: 'top-center',
      timeout: 5000,
      closeOnClick: true,
      pauseOnFocusLoss: true,
      pauseOnHover: true,
      draggable: true,
      draggablePercent: 0.6,
      showCloseButtonOnHover: false,
      hideProgressBar: true,
      closeButton: 'button',
      icon: true,
      rtl: false
    });
    Vue.use(VuejsDialog);
    Vue.use(GAuth, {
      clientId: '685178565742-mhla4a26v6vaohfe5pga87572hdnhgb2.apps.googleusercontent.com',
      scope: 'email',
      prompt: 'select_account',
      fetch_basic_profile: true
    });

    Vue.use(VueCookies);
    Vue.use(VueObserveVisibility);
    Vue.use(Buefy, {
      defaultIconPack: 'fas'
    });
    Vue.use(VModal, { componentName: 'v-modal' });
    Vue.use(PortalVue);

    await loadAsyncComponents({ router });
  },

  async afterApp({ app, router }) {
    router.onReady(() => {
      app.$mount('#app');
    });
  }
});
