import { request, post } from '@/utils/api/client';

export type PaymentData = {
  clientSecret: string;
};

export const settlePayment = async (reservationId: string): Promise<void> => {
  try {
    await request('/payment/confirm', 'patch', { reservationId });
  } catch (error) {
    throw error;
  }
};

export const payReservation = async (
  reservationId: string,
  paymentInstrumentExternalId: string
): Promise<PaymentData> => {
  return post<PaymentData>('/payment/pay', { reservationId, paymentInstrumentExternalId });
};
