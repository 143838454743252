import { RENTAL } from '@/models/carType';
import store from '@/store';
import { DEFAULT_DATE_FORMAT } from '@/utils/consts';
import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export class RoutingMixin extends Vue {
  resetReservationDatesInState() {
    store.dispatch('setReservationStartDate', moment().format(DEFAULT_DATE_FORMAT));
    store.dispatch('setReservationEndDate', moment().format(DEFAULT_DATE_FORMAT));
  }

  navigateToRental() {
    this.$router.push({
      path: RENTAL,
      query: {
        from: moment().format(DEFAULT_DATE_FORMAT),
        to: moment().format(DEFAULT_DATE_FORMAT)
      }
    });
  }
}
