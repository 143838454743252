const HomeRoute = 'home',
  VanSelectionRoute = 'vans',
  ReservationRoute = 'reservation',
  PaymentRoute = 'payment',
  PartnerRoute = 'stante-se-partnerem',
  MikrobusRoute = 'pujc-si-mikrobus',
  LogisticsRoute = 'kakadoo-logistika',
  HowItWorksRoute = 'how-it-works',
  HowItWorksEngRoute = 'how-it-works-eng',
  FAQRoute = 'faq',
  ConditionsRoute = 'conditions',
  ConditionsFutureRoute = 'conditions-future',
  PrivacyPolicyRoute = 'privacy-policy',
  PrivacyPolicyFutureRoute = 'privacy-policy-future',
  ContactsRoute = 'contacts',
  MapRoute = 'map',
  MapServisRoute = 'map-servis',
  MapVanRoute = 'map-van-detail',
  ReturnFormRoute = 'return-form',
  PickupFormRoute = 'pickup-form',
  PriceListRoute = 'pricelist',
  PriceListFutureRoute = 'pricelist-future',
  CookiesRoute = 'cookies',
  ResetPasswordRoute = 'reset-password',
  RegistrationConfirmRoute = 'registration-confirm',
  LoginRoute = 'login',
  UnavailableRoute = 'unavailable',
  UserProfileRoute = 'user-profile',
  UserProfileDocumentsRoute = 'user-documents',
  UserProfilePaymentRoute = 'user-payment';

export {
  HomeRoute,
  VanSelectionRoute,
  ReservationRoute,
  PaymentRoute,
  PartnerRoute,
  HowItWorksRoute,
  HowItWorksEngRoute,
  FAQRoute,
  ConditionsRoute,
  ConditionsFutureRoute,
  PrivacyPolicyRoute,
  PrivacyPolicyFutureRoute,
  ContactsRoute,
  MapRoute,
  MapServisRoute,
  MapVanRoute,
  MikrobusRoute,
  LogisticsRoute,
  ReturnFormRoute,
  PickupFormRoute,
  PriceListRoute,
  PriceListFutureRoute,
  CookiesRoute,
  ResetPasswordRoute,
  RegistrationConfirmRoute,
  LoginRoute,
  UnavailableRoute,
  UserProfileRoute,
  UserProfileDocumentsRoute,
  UserProfilePaymentRoute
};
