










import { Component, Vue, Watch } from 'vue-property-decorator';
import store from '@/store';
import NoSSR from 'vue-client-only';

import { Route } from 'vue-router';
import { MapServisRoute } from '@/router/routes';

const CookieConsent = () => import('@/components/cookieConsent.vue');
const UnavailablePage = () => import('@/pages/unavailable.vue');

@Component({
  components: {
    'no-ssr': NoSSR,
    UnavailablePage,
    CookieConsent
  },
  metaInfo: {
    script: [
      {
        src: 'https://cdn.jsdelivr.net/gh/orestbida/cookieconsent@v2.8.5/dist/cookieconsent.js'
      }
    ]
  }
})
export default class App extends Vue {
  get isOnBlacklist() {
    return store.state.loggedUser?.onBlacklist;
  }

  get showCookieConsent() {
    return store.state.showCookieSettings;
  }

  mounted() {
    store.dispatch('fetchLoggedUser');
  }

  @Watch('$route', { immediate: true, deep: true })
  routeChangeListener(to: Route) {
    if (!this.__SSR) {
      const body = document.getElementsByTagName('body')[0];
      if (body) {
        if (to.name === MapServisRoute) {
          body.style.overflow = 'hidden';
        } else {
          body.style.overflow = '';
        }
      }
    }
  }

  @Watch('showCookieConsent')
  onShowCookieConsent(show: boolean) {
    const cookieConsent = this.$refs.cookieConsent;
    if (show) {
      // @ts-ignore
      cookieConsent.showSettings();
      store.dispatch('toggleCookieSettings', false);
    }
  }
}
