import Vue from 'vue';
import Vuex, { ActionTree, StoreOptions, MutationTree } from 'vuex';
import { RootState } from '@/store/types';
import UserService from '../services/user.service';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from '@/utils/consts';
Vue.use(Vuex);

const state: RootState = {
  carsWithAvailability: [],
  carsAvailable: [],
  allCars: [],
  searchParameters: {
    start: null,
    end: null
  },
  selectedCar: null,
  mouseoverCar: null,
  isCameraHandlerVisible: false,
  forceCloseCamera: '',
  loggedUser: undefined,
  fetchingLoggedUser: true,
  subscribesToNewsletter: false,
  cameraCallback: () => {},
  reservationStartDate: moment().format(),
  reservationEndDate: moment().format(),
  reservationStartDisableDates: {
    to: moment().subtract(1, 'days').endOf('day').toDate(),
    from: moment().add(6, 'months').toDate()
  },
  reservationEndDisableDates: {
    to: moment().isAfter(moment(21, 'HH')) ? moment().toDate() : moment().subtract(1, 'days').endOf('day').toDate(),
    from: moment().add(364, 'days').toDate()
  },
  showCookieSettings: false,
  carTypeUrl: '',
  cityUrl: '',
  documentsValid: false,
  reservationCheckoutLoading: false,
  activeDiscount: null,
  googleRating: 4.5,
  carQuerySlugs: [],
  parkingQuerySlugs: [],
  eligiblePrimaryParams: [],
  vehicleFilters: [],
  parkingFilters: []
};

const mutations: MutationTree<RootState> = {
  SET_AVAILABILITY_CARS(state, newData) {
    state.carsWithAvailability = newData;
  },
  SET_AVAILABLE_CARS(state, newData) {
    state.carsAvailable = newData;
  },
  SET_ALL_CARS(state, newData) {
    state.allCars = newData;
  },
  SET_SEARCH_PARAMETERS(state, newData) {
    state.searchParameters = newData;
  },
  SET_SELECTED_CAR(state, newData) {
    state.selectedCar = newData;
  },
  SET_MOUSEOVER_CAR(state, newData) {
    state.mouseoverCar = newData;
  },
  OPEN_CAMERA(state, callback) {
    state.isCameraHandlerVisible = true;
    state.cameraCallback = callback;
  },
  CLOSE_CAMERA(state) {
    state.isCameraHandlerVisible = false;
    state.cameraCallback = () => {};
  },
  FORCE_CLOSE_CAMERA(state) {
    state.forceCloseCamera = Date.now().toString();
    state.cameraCallback = () => {};
  },
  SET_LOGGED_USER(state, newData) {
    state.loggedUser = newData;
  },
  SET_NEWSLETTER_SUBSCRIBED(state, newData) {
    state.subscribesToNewsletter = newData;
  },
  SET_RESERVATION_START_DATE(state, newData) {
    state.reservationStartDate = newData;
    state.reservationEndDisableDates = {
      to: moment(newData).set('hour', 14).subtract(1, 'days').endOf('day').toDate(),
      from: moment(newData).set('hour', 14).add(364, 'days').toDate()
    };
  },
  SET_RESERVATION_END_DATE(state, newData) {
    state.reservationEndDate = newData;
  },
  CHECK_RESERVATION_DATES_CONSISTENCY(state) {
    const dateDiff = moment(state.reservationEndDate).diff(moment(state.reservationStartDate), 'days');
    if (moment(state.reservationStartDate).isAfter(moment(state.reservationEndDate))) {
      state.reservationEndDate = moment(state.reservationStartDate).format(DEFAULT_DATE_FORMAT);
    } else if (dateDiff >= 364) {
      state.reservationEndDate = moment(state.reservationStartDate).add(364, 'days').format(DEFAULT_DATE_FORMAT);
    }
  },
  TOGGLE_COOKIE_SETTINGS(state, newData) {
    state.showCookieSettings = newData;
  },
  SET_CARTYPE_URL(state, newData) {
    state.carTypeUrl = newData;
  },
  SET_CITY_URL(state, newData) {
    state.cityUrl = newData;
  },
  SET_FETCHING_LOGGED_USER(state, fetching) {
    state.fetchingLoggedUser = fetching;
  },
  TOGGLE_RESERVATION_CHECKOUT_LOADING(state) {
    state.reservationCheckoutLoading = !state.reservationCheckoutLoading;
  },
  SET_ACTIVE_DISCOUNT(state, newData) {
    state.activeDiscount = newData;
  },
  SET_DOCUMENTS_VALID(state, newData) {
    state.documentsValid = newData;
  },
  SET_CAR_QUERY_SLUGS(state, newData) {
    state.carQuerySlugs = newData;
  },
  SET_PARKING_QUERY_SLUGS(state, newData) {
    state.parkingQuerySlugs = newData;
  },
  SET_ELIGIBLE_PRIMARY_PARAMS(state, newData) {
    state.eligiblePrimaryParams = newData;
  },
  SET_VEHICLE_FILTERS(state, newData) {
    state.vehicleFilters = newData;
  },
  SET_PARKING_FILTERS(state, newData) {
    state.parkingFilters = newData;
  },
  SET_GOOGLE_RATING(state, newData) {
    state.googleRating = newData;
  }
};

// eslint-disable-next-line
const actions: ActionTree<RootState, any> = {
  setAvailabilityCars({ commit }, newData) {
    commit('SET_AVAILABILITY_CARS', newData);
  },
  setAvailableCars({ commit }, newData) {
    commit('SET_AVAILABLE_CARS', newData);
  },
  setAllCars({ commit }, newData) {
    commit('SET_ALL_CARS', newData);
  },
  setSearchParameters({ commit }, newData) {
    commit('SET_SEARCH_PARAMETERS', newData);
  },
  setSelectedCar({ commit }, newData) {
    commit('SET_SELECTED_CAR', newData);
  },
  setMouseoverCar({ commit }, newData) {
    commit('SET_MOUSEOVER_CAR', newData);
  },
  openCamera({ commit }, newData) {
    commit('OPEN_CAMERA', newData);
  },
  closeCamera({ commit }, newData) {
    commit('CLOSE_CAMERA', newData);
  },
  forceCloseCamera({ commit }, newData) {
    commit('FORCE_CLOSE_CAMERA', newData);
  },
  async fetchLoggedUser({ commit }) {
    commit('SET_FETCHING_LOGGED_USER', true);
    try {
      const user = await UserService.me();
      commit('SET_LOGGED_USER', user);
    } catch (e) {
      commit('SET_LOGGED_USER', undefined);
    } finally {
      commit('SET_FETCHING_LOGGED_USER', false);
    }
  },
  forgotUser({ commit }) {
    commit('SET_LOGGED_USER', null);
  },
  setNewsletterStatus({ commit }) {
    commit('SET_NEWSLETTER_SUBSCRIBED', state.loggedUser?.user.subscribesToNewsletter);
  },
  setReservationStartDate({ commit }, newData) {
    commit('SET_RESERVATION_START_DATE', moment(newData).format(DEFAULT_DATE_FORMAT));
    commit('CHECK_RESERVATION_DATES_CONSISTENCY');
  },
  setReservationEndDate({ commit }, newData) {
    commit('SET_RESERVATION_END_DATE', moment(newData).format(DEFAULT_DATE_FORMAT));
    commit('CHECK_RESERVATION_DATES_CONSISTENCY');
  },
  toggleCookieSettings({ commit }, newData) {
    commit('TOGGLE_COOKIE_SETTINGS', newData);
  },
  setCarTypeUrl({ commit }, newData) {
    commit('SET_CARTYPE_URL', newData);
  },
  setCityUrl({ commit }, newData) {
    commit('SET_CITY_URL', newData);
  },
  toggleReservationCheckoutLoading({ commit }, newData) {
    commit('TOGGLE_RESERVATION_CHECKOUT_LOADING', newData);
  },
  setActiveDiscount({ commit }, newData) {
    commit('SET_ACTIVE_DISCOUNT', newData);
  },
  setDocumentsValid({ commit }, newData) {
    commit('SET_DOCUMENTS_VALID', newData);
  },
  setCarQuerySlugs({ commit }, newData) {
    commit('SET_CAR_QUERY_SLUGS', newData);
  },
  setParkingQuerySlugs({ commit }, newData) {
    commit('SET_PARKING_QUERY_SLUGS', newData);
  },
  setEligiblePrimaryParams({ commit }, newData) {
    commit('SET_ELIGIBLE_PRIMARY_PARAMS', newData);
  },
  setVehicleFilters({ commit }, newData) {
    commit('SET_VEHICLE_FILTERS', newData);
  },
  setParkingFilters({ commit }, newData) {
    commit('SET_PARKING_FILTERS', newData);
  },
  setGoogleRating({ commit }, newData) {
    commit('SET_GOOGLE_RATING', newData);
  }
};

const store: StoreOptions<RootState> = {
  state,
  mutations,
  actions
};

export default new Vuex.Store(store);
