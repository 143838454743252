






import { Component, Vue, Watch } from 'vue-property-decorator';
import store from '@/store';

/**
 * TODO Would be great to remove the keep-alive component wrapper, because it caches state data
 *      when moving among routes and also it breaks component life cycle methods
 */

@Component({
  components: {}
})
export default class ProfileView extends Vue {
  get fetchingLoggedUser() {
    return store.state.fetchingLoggedUser;
  }

  get loggedUser() {
    return store.state.loggedUser;
  }

  @Watch('fetchingLoggedUser')
  checkIfLogged() {
    if (!this.fetchingLoggedUser && !this.loggedUser) {
      this.$toast.error('Musíte být přihlášeni, abyste mohli přistoupit do této sekce webové stránky.');
      this.$router.push('/');
    }
  }
}
