/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * API client wrapper
 * Handles error result and messages from backend service
 */

import axios, { AxiosError, AxiosRequestConfig } from 'axios';

type RequestMethod = 'post' | 'get' | 'patch';

type RequestData = Partial<Record<string, any>>;

type ResponseData = any;

type RequestConfig = {
  defaultError?: string;
} & AxiosRequestConfig;

export async function request<T = ResponseData>(
  url: string,
  method: RequestMethod,
  data?: RequestData,
  requestConfig?: RequestConfig
): Promise<T> {
  try {
    const response = await axios.request<T>({
      url,
      method,
      data,
      ...requestConfig
    });
    return response.data;
  } catch (err) {
    const e = err as AxiosError;
    if (e.response && e.response.data) {
      if (e.response.data.requiredPermission) {
        console.log(`Missing the required permission: ${e.response.data.requiredPermission}`);
      }
      throw new Error(e.response.data.message);
    } else {
      throw new Error(requestConfig?.defaultError || 'Někde se stala chyba, opakujte akci později.');
    }
  }
}

export async function post<T = ResponseData>(url: string, data?: RequestData, config?: RequestConfig): Promise<T> {
  return request<T>(url, 'post', data, config);
}
