



















import { Component, Prop, Vue } from 'vue-property-decorator';
import store from '@/store';

@Component
export default class Navbar extends Vue {
  @Prop() readonly title: string | undefined;
  @Prop({ default: true }) readonly fixed!: boolean;

  get hasContent(): boolean {
    return !!this.$slots['content'] || !!this.$scopedSlots['content'];
  }

  get reservationCheckoutLoading() {
    return store.state.reservationCheckoutLoading;
  }
}
