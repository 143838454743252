
























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component
export default class CheckboxField extends Vue {
  @Prop({ required: true }) readonly value!: boolean;
  @Prop({ required: true, type: String }) readonly name!: string;
  @Prop({ required: false, type: String, default: null }) readonly error!: string | null;
  @Prop({ required: false, type: Boolean, default: false }) readonly indeterminate!: boolean;

  // HTML5 standard only supports the assignment of the indeterminate property via JS
  @Watch('indeterminate') onIndeterminateChange() {
    const checkbox = this.$refs['checkbox'];
    if (checkbox) {
      (checkbox as HTMLInputElement).indeterminate = this.indeterminate;
    }
  }
  mounted() {
    this.onIndeterminateChange();
  }
}
