import { StripeError as StripeErrorInterface } from '@stripe/stripe-js';

export class StripeError extends Error {
  constructor(message: string, public readonly cause: StripeErrorInterface) {
    super(message);
  }

  get code() {
    return this.cause.code as StripeErrorCode | undefined;
  }
  get declineCode() {
    return this.cause.decline_code as StripeDeclineCode | undefined;
  }
}

export class StripeClientNotAvailable extends Error {}

export class StripePaymentError extends StripeError {}

export class StripeConfirmSetupIntentError extends StripeError {}

export enum StripeErrorCode {
  CardDeclined = 'card_declined',
  CardExpired = 'expired_card',
  ProcessingError = 'processing_error'
}

export enum StripeDeclineCode {
  GenericDecline = 'generic_decline',
  InsufficientFunds = 'insufficient_funds'
}
