






import { Component, Vue, Prop } from 'vue-property-decorator';
import { CheckoutStep } from '@/services/checkout.service';
import ProgressBar from './progressBar/ProgressBar.vue';

@Component({
  components: { ProgressBar }
})
export default class CheckoutHeader extends Vue {
  @Prop({ required: true }) readonly activeStep!: number;

  get step() {
    if (this.activeStep === 1) {
      return CheckoutStep.CONTACT_INFO;
    } else if (this.activeStep === 2) {
      return CheckoutStep.IDENTITY_VERIFICATION;
    } else {
      return CheckoutStep.PAYMENT_CONFIRMATION;
    }
  }
}
