




































import { Component, Prop, Vue } from 'vue-property-decorator';
import { EyeIcon, EyeOffIcon } from 'vue-feather-icons';

@Component({
  components: { EyeIcon, EyeOffIcon }
})
export default class TextField extends Vue {
  @Prop({ required: false, type: String, default: 'text' }) readonly type!: string;
  @Prop({ required: false, type: String, default: '' }) placeholder!: string;
  @Prop({ required: false, type: String }) readonly value!: string;
  @Prop({ required: false, type: String }) readonly label!: string;
  @Prop({ required: false, type: String }) readonly name!: string;
  @Prop() readonly error?: string | null;
  @Prop() readonly help?: string | null;

  private showPassword = false;
  private inputRef!: HTMLInputElement;
  private selectionStart = 0;
  private selectionEnd = 0;
  private placeholderBackup = '';

  hidePlaceholder() {
    if (this.placeholder !== '') {
      this.placeholderBackup = this.placeholder;
      this.placeholder = '';
    }
  }
  showPlaceholder() {
    if (this.placeholder == '') {
      this.placeholder = this.placeholderBackup;
    }
  }

  focus() {
    this.inputRef.focus();
  }

  get inputType() {
    if (this.type !== 'password') {
      return this.type;
    }
    return this.showPassword ? 'text' : 'password';
  }

  private toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  mounted() {
    this.inputRef = this.$refs.input as HTMLInputElement;
  }

  beforeUpdate() {
    this.selectionStart = this.inputRef.selectionStart || 0;
    this.selectionEnd = this.inputRef.selectionEnd || 0;
  }

  updated() {
    this.inputRef.focus();
    this.inputRef.setSelectionRange(this.selectionStart, this.selectionEnd);
  }
}
