<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.39 8V16C21.39 18.7614 19.1514 21 16.39 21H8.38998C5.62856 21 3.38998 18.7614 3.38998 16V8C3.38998 5.23858 5.62856 3 8.38998 3H16.39C19.1514 3 21.39 5.23858 21.39 8Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.39 21C11.39 18 11.39 15 11.39 12C11.39 9.8125 11.89 8 15.39 8"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.38998 13H11.39H15.39"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'FacebookIcon'
};
</script>
