import { Component, Vue } from 'vue-property-decorator';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import config from '@/config';
import { settlePayment } from '@/services/payment.service';
import {
  StripeClientNotAvailable,
  StripeConfirmSetupIntentError,
  StripeDeclineCode,
  StripeError,
  StripeErrorCode,
  StripePaymentError
} from '@/mixins/stripe/Stripe.errors';
import userService from '@/services/user.service';

@Component
export class StripeMixin extends Vue {
  private stripeClient: Stripe | null = null;

  async getStripeClient(): Promise<Stripe> {
    if (!this.stripeClient) {
      const { loadStripe } = await import(/* webpackChunkName: "stripe" */ '@stripe/stripe-js');
      const client = await loadStripe(config.getValue<string>('stripePublicKey'));
      if (!client) {
        throw new StripeClientNotAvailable('Stripe client is not available');
      }
      return (this.stripeClient = client);
    }
    return this.stripeClient;
  }

  async setupPaymentCardForm(domIdentifier: string) {
    const { clientSecretToken } = await userService.newPaymentInstrument();

    const client = await this.getStripeClient();
    const elements = client.elements({
      clientSecret: clientSecretToken,
      locale: 'cs'
    });

    const cardNumberEl = elements.create('payment');
    cardNumberEl.mount(domIdentifier);

    return elements;
  }

  async confirmCardSetup(elements: StripeElements) {
    const client = await this.getStripeClient();
    const { setupIntent, error } = await client.confirmSetup({
      elements,
      redirect: 'if_required'
    });
    if (error) {
      throw new StripeConfirmSetupIntentError('Confirm card setup failed', error);
    }
    if (!setupIntent) {
      throw new Error('SetupIntent is not available after confirming');
    }
    const { status, payment_method } = setupIntent;
    if (status !== 'succeeded' || !payment_method) {
      throw new Error('Confirming setupIntent was not successful');
    }
  }

  async confirmPayment(clientSecret: string, orderId: string): Promise<void> {
    const client = await this.getStripeClient();

    const stripeResponse = await client.confirmCardPayment(clientSecret);
    if (!stripeResponse) {
      throw new Error('Error happened while confirming card payment');
    }
    if (stripeResponse.error) {
      throw new StripePaymentError('Confirming payment failed', stripeResponse.error);
    }
    await settlePayment(orderId);
  }

  handleConfirmPaymentError(error: Error | StripePaymentError): void {
    let errorMessage = '';
    if (error instanceof StripePaymentError) {
      console.log('Expected error happened during payment confirmation.', error);
      errorMessage = error.cause.message ?? 'Nepodařilo se dokončit platbu, opakujte prosím akci později.';
    } else {
      console.log('Unexpected error happened during payment confirmation.', error);
      errorMessage = (error as Error).message ?? 'Neočekávaná chyba, opakujte prosím akci později.';
    }
    this.$toast.error(errorMessage);
  }

  protected parseConfirmSetupIntentErrorMessage(error: StripeError): string {
    if (error.code === StripeErrorCode.ProcessingError) {
      return 'Uložení karty se nedalo zprocesovat, zkuste to prosím později.';
    }
    if (error.code === StripeErrorCode.CardExpired) {
      return 'Použitá karta je už expirovaná.';
    }
    if (error.declineCode) {
      switch (error?.declineCode) {
        case StripeDeclineCode.InsufficientFunds:
          return 'Na kartě nemáte dostatek finančních prostředků.';
        case StripeDeclineCode.GenericDecline:
        default:
          return 'Vaše karta byla zamítnuta.';
      }
    }
    return 'Něco se pokazilo, opakujte akci prosím později.';
  }
}
