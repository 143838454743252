












































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { ButtonColor } from '@/components/ui/Button';

@Component
export default class Button extends Vue {
  @Prop({ default: false }) readonly full!: boolean;
  @Prop({ default: false }) readonly disabled!: boolean;
  @Prop({ default: 'light' }) readonly color!: ButtonColor;
  @Prop({ required: true, default: '' }) readonly label!: string;
  @Prop({ default: false }) readonly loading!: boolean;
  @Prop({ default: '' }) readonly loadingText!: string;
  @Prop({ default: 'medium' }) readonly size!: string;
  @Prop({ default: 'button' }) readonly type!: string;

  private labelWidth!: number;

  $refs!: {
    label: HTMLDivElement;
  };

  hasSlot(name: string): boolean {
    return !!this.$slots[name];
  }

  @Emit('click')
  onClick() {}

  mounted() {
    this.labelWidth = this.$refs.label.clientWidth;
  }

  @Watch('label', { immediate: false })
  recalculateLabelWidth() {
    if (this.$refs.label) {
      this.labelWidth = this.$refs.label.clientWidth;
    }
  }
}
