









import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Heading extends Vue {
  @Prop({ required: true }) readonly title!: string;
  @Prop({ required: false, default: 'left' }) readonly align!: string;

  get hasContent(): boolean {
    return !!this.$slots['default'];
  }
}
