








import { Component, Vue } from 'vue-property-decorator';
import PageFooter from '@/components/footer.vue';

/**
 * TODO Would be great to remove the keep-alive component wrapper, because it caches state data
 *      when moving among routes and also it breaks component life cycle methods
 */

@Component({
  components: {
    PageFooter
  }
})
export default class Default extends Vue {}
