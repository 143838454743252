














import { Component, Mixins } from 'vue-property-decorator';
import NavbarNew from '@/components/navbarNew.vue';
import UserNavigation from '@/components/user/userNavigation.vue';
import FooterNew from '@/components/footerNew.vue';
import CameraHandler from '@/components/cameraHandler.vue';
import { AuthMixin } from '@/mixins/user/Auth.mixin';

@Component({
  components: { NavbarNew, UserNavigation, FooterNew, CameraHandler }
})
export default class UserProfile extends Mixins(AuthMixin) {}
