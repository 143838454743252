import store from '@/store';
import { Permissions } from '../models/roles';
import { ForgotPasswordResponse, MeResponse, RegisterResponse, ReservationDetail } from '@/services/model/user';
import { AuthorizedUser, FormData, UserDocumentFormData, UserDocuments } from '@/store/types';
import { post, request } from '@/utils/api/client';
import { fileToBase64 } from '@/utils/file';
import { PaymentInstrument } from '@/models/paymentInstrument';
import { UserUpdateUserDocsInformationInput } from '@/models/user';

class UserService {
  public async login(email: string, password: string) {
    await post(
      '/user/login',
      {
        email,
        password
      },
      {
        defaultError: 'Nepodařilo se zpracovat požadavek na přihlášení uživatele přes služby Google.'
      }
    );
  }

  public async loginGoogleUser(email: string, googleIdToken: string) {
    await post('/user/login', {
      email,
      googleIdToken
    });
  }

  public async logout() {
    await request('/user/logout', 'get');
  }

  public async register(
    email: string,
    password: string,
    reservationDetail?: ReservationDetail
  ): Promise<RegisterResponse> {
    return await post<RegisterResponse>(
      '/user/register',
      {
        email,
        password,
        reservationDetail
      },
      {
        defaultError: 'Nepodařilo se zpracovat požadavek na registraci.'
      }
    );
  }

  public async forgotPassword(email: string, reservationDetail?: ReservationDetail): Promise<boolean> {
    const response = await post<ForgotPasswordResponse>('/user/reset-password', {
      email,
      reservationDetail
    });
    return response.status;
  }

  public async checkResetPasswordToken(resetPasswordToken: string) {
    await post('/user/check-reset-password-token', {
      resetPasswordToken
    });
  }

  public async confirmRegistration(registerToken: string) {
    await post('/user/confirm-registration', {
      registerToken
    });
  }

  public async resetPassword(resetPasswordToken: string, password: string) {
    await post('/user/set-new-password', {
      password,
      resetPasswordToken
    });
  }

  public async me(): Promise<AuthorizedUser | null> {
    try {
      const { data } = await request<MeResponse>('/user/me', 'get');
      // TODO Remove this fallback
      // @ts-ignore
      if (data?.logged === false) {
        return null;
      }
      return data;
    } catch (error) {
      await store.dispatch('forgotUser');
      throw error;
    }
  }

  public async updateUserDocsInformation(args: UserUpdateUserDocsInformationInput): Promise<void> {
    await request('/user/user-docs', 'patch', args);
  }

  public async getDriver() {
    const res = await request('/user/driver', 'get');
    return res.data.driverName;
  }

  public async updateProfile(profile: Partial<FormData>): Promise<void> {
    await post('/user/profile', { ...profile, subscribesToNewsletter: undefined });
    await store.dispatch('fetchLoggedUser');
  }

  public async getDocuments(): Promise<UserDocuments> {
    const response = await request('/user/documents', 'get');
    return response.data;
  }

  public async updateDocuments(documents: UserDocumentFormData): Promise<UserDocuments> {
    const postData: {
      [key: string]: {
        [key: string]: string;
      } | null;
    } = {};
    for (const [key, document] of Object.entries(documents)) {
      postData[key] = document
        ? {
            name: document.name,
            content: await fileToBase64(document)
          }
        : null;
    }
    return (await post('/user/documents', postData)).data;
  }

  public async getPaymentInstruments(): Promise<PaymentInstrument[]> {
    return request('/user/payment-instruments', 'get').then(({ data }) => data);
  }

  public async newPaymentInstrument(): Promise<{ clientSecretToken: string }> {
    return post('/user/payment-instruments', {}).then(({ data }) => data);
  }

  public async isAllowedTo(permission: Permissions) {
    if (!store.state.loggedUser) {
      // if we need to check if a user is allowed to do something based on their permissions, that user has to be authenticated
      return false;
    }
    const response = await request(`/user/${store.state.loggedUser.id}/is-allowed-to`, 'get', undefined, {
      params: { permission: permission }
    });
    return response.data;
  }
}

export default new UserService();
