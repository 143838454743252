import { Component, Vue } from 'vue-property-decorator';
import featureFlagService, { FeatureFlag } from '@/services/featureFlag.service';

@Component({})
export class GtagMixin extends Vue {
  public gtagConsoleLogsEnabled = false;

  async mounted() {
    this.gtagConsoleLogsEnabled = await featureFlagService.getFeatureFlagActiveByName(
      FeatureFlag.SHOW_GTAG_CONSOLE_LOGS
    );
  }

  get gtag() {
    if (!this.$gtag) {
      console.error('Gtag is not available');
      return;
    } else {
      return this.$gtag;
    }
  }

  public triggerGtagEvent(
    name: string,
    event?: ('click' | 'view' | 'select' | 'open' | 'purchase' | 'conversion') | '',
    gtagParameters?: {}
  ) {
    if (this.gtag) {
      if (this.gtagConsoleLogsEnabled) {
        console.log(
          JSON.stringify({ Gtag: { name: name + '.' + event, Parameters: gtagParameters || 'None' } }, null, 2)
        );
      }
      this.gtag.event(`${name}.${event}`, gtagParameters);
    } else {
      console.error("Event can't be triggered, Gtag is not available");
    }
  }
}
