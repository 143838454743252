import { Component, Prop, Vue } from 'vue-property-decorator';
import Loading from '@/components/ui/Loading.vue';

@Component({
  components: {
    Loading
  }
})
export class FileUploadMixin extends Vue {
  @Prop({ required: true, default: null }) readonly documentPath!: string | null;
  @Prop({ required: true, default: false }) public loading!: boolean;
  public url: string | null = null;

  async validateFileSize(file: File): Promise<File | null> {
    const maxFileSize = 10000000;
    if (file.size > maxFileSize) {
      this.showMaxFileSizeError();
      return null;
    }
    return file;
  }

  showMaxFileSizeError() {
    this.$toast.error(`Maximální velikost souboru je 10 MB.`);
  }

  isUrlPdf(path: string): boolean {
    if (path.toLowerCase().endsWith('.pdf')) {
      return true;
    }
    const url = new URL(path);
    const pathParam = url.searchParams.get('path');
    return pathParam ? pathParam.toLowerCase().endsWith('.pdf') : false;
  }

  setUrl(file: File) {
    this.url = URL.createObjectURL(file);
  }
}
