




























































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import { CameraIcon, UploadIcon, TrashIcon, FileTextIcon } from 'vue-feather-icons';
import store from '@/store';
import { isMobileDevice } from '@/utils/userAgent';
import { base64toFile } from '@/utils/file';
import PhotoModal from './PhotoModal.vue';
import { FileUploadMixin } from '@/mixins/fileUpload/FileUpload.mixin';
import Config from './../../../config';
import moment from 'moment';

@Component({
  components: {
    CameraIcon,
    UploadIcon,
    TrashIcon,
    FileTextIcon,
    PhotoModal
  }
})
export default class PhotoField extends Mixins(FileUploadMixin) {
  @Prop({ required: false, default: null }) readonly icon!: string | null;
  @Prop({ required: false, default: null }) readonly title!: string | null;
  @Prop({ required: false, default: null }) readonly description!: string | null;
  @Prop({ required: true, default: '' }) readonly outputFilename!: string;
  @Prop({ required: false, default: false }) readonly error!: boolean | null;
  @Prop({ required: true, default: null }) readonly documentExpiration!: Date | null;

  public isPhotoModalOpen = false;

  get cameraEnabled(): boolean {
    return !isMobileDevice();
  }

  get hasIcon(): boolean {
    return !!this.$slots['icon'];
  }

  get isUserDocumentExpired() {
    return moment(this.documentExpiration).isBefore(moment(), 'day');
  }

  //validated in the sense of 1.not expired 2.validated from the admin (expiration !== null)
  get isUserDocumentValidated() {
    return !this.isUserDocumentExpired && !!this.documentExpiration;
  }

  togglePhotoModal() {
    this.isPhotoModalOpen = !this.isPhotoModalOpen;
  }

  mounted() {
    this.loadReturnFormImage();
  }

  @Watch('documentPath')
  loadReturnFormImage() {
    this.url = this.documentPath ? Config.getValue('backendUrl') + `/document/content?path=${this.documentPath}` : null;
  }

  async onChange(e: Event) {
    this.loading = true;
    // @ts-ignore
    const file = e.target.files[0];

    if (!file) {
      this.loading = false;
      return;
    }

    if (!this.isSupportedFile(file.name)) {
      this.$toast.error('Vyberte prosím obrázek nebo PDF soubor');
      this.loading = false;
      return;
    }

    const validatedFile = await this.validateFileSize(file);
    if (validatedFile) {
      this.setUrl(validatedFile);
    }
    this.$emit('input', validatedFile);
  }

  isSupportedFile(filename: string): boolean {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'heic', 'pdf'];
    return imageExtensions.some((ext) => filename.toLowerCase().endsWith(ext));
  }

  async openCamera() {
    const { default: reduce } = await import('image-blob-reduce');
    store.dispatch('openCamera', (imageInBase64: string) => {
      const newFile = base64toFile(imageInBase64, (this.outputFilename ?? '') + '.jpg');
      reduce()
        .toBlob(newFile, { max: 1000 })
        .then((blob: Blob) => {
          const reducedFile = new File([blob], newFile.name);
          this.url = imageInBase64;
          this.$emit('input', reducedFile);
        });
    });
  }
}
