









import { Component, Vue, Prop } from 'vue-property-decorator';
import { XIcon } from 'vue-feather-icons';

@Component({
  components: {
    XIcon
  }
})
export default class PhotoModal extends Vue {
  @Prop({ required: true, default: '' }) readonly imgUrl!: string;

  closeModal() {
    this.$emit('togglePhotoModal');
  }
}
