

















import { Component, Mixins } from 'vue-property-decorator';
import UserPageLayout from '@/components/user/userPageLayout.vue';
import { AuthMixin } from '@/mixins/user/Auth.mixin';
import PaymentMethods from '@/components/user/payment/PaymentMethods.vue';

@Component({
  components: {
    PaymentMethods,
    UserPageLayout
  }
})
export default class UserPaymentPage extends Mixins(AuthMixin) {}
