<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_545_8413)">
      <path
        d="M30.4001 10.409H26.8001C26.1374 10.409 25.6001 9.87175 25.6001 9.20903V4.00903M30.4001 7.20903V13.609"
        stroke="#111111"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.6001 6.409C17.6001 4.9106 18.973 3.73316 20.5295 4.06564C21.7292 4.32191 22.4972 5.55572 22.3828 6.7778C22.0875 9.93285 18.0517 12.1192 17.6127 13.3447C17.5666 13.4733 17.6586 13.609 17.7954 13.609H22.4001"
        stroke="#111111"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.3997 23.6609C29.0226 23.2841 27.6477 21.9076 26.528 20.7889C25.4083 19.6702 23.5933 19.6702 22.4738 20.7889C21.3543 21.9076 20.7538 22.5095 20.457 22.8062C19.1017 24.1608 15.9336 23.0513 12.8447 19.9632C9.75571 16.875 8.62375 13.7329 10.0035 12.3523C10.2301 12.1256 10.9008 11.4555 12.0203 10.336C13.1399 9.21644 13.1399 7.40156 12.0203 6.28204C10.9008 5.16252 10.4827 4.743 9.14899 3.4094C7.81523 2.0758 5.65261 2.07575 4.31891 3.4094C4.05721 3.67109 3.13536 4.59376 2.86195 4.86716C-0.22674 7.95575 2.45379 15.6241 9.81955 22.9905C17.1853 30.3569 24.8541 33.0358 27.9429 29.9475C28.1067 29.7837 29.1291 28.7605 29.3999 28.4897C30.7335 27.1561 30.7335 24.9937 29.3997 23.6609Z"
        stroke="#111111"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_545_8413">
        <rect width="32" height="32" fill="white" transform="translate(0 0.809082)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'Phone24Icon'
};
</script>
