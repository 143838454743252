






















import { Component, Mixins, Watch } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';

import { CarReservation } from '@/store/types';

import { HomeRoute } from '@/router/routes';
import { getCar, isCarAvailable } from '@/services/reservations.service';
import CheckoutHeader from '@/components/checkout/layout/navbar/CheckoutHeader.vue';
import PageLayout from '@/components/checkout/PageLayout.vue';
import { AuthMixin } from '@/mixins/user/Auth.mixin';
import Loading from '@/components/ui/Loading.vue';
import moment from 'moment';
import { RoutingMixin } from '@/components/screens/checkout/Routing.mixin';
import { DEFAULT_DATE_FORMAT } from '@/utils/consts';

const CheckoutUserLogin = () =>
  import(/* webpackChunkName: "screens.checkout.step-login" */ '@/components/screens/checkout/CheckoutStepLogin.vue');
const CheckoutStepOne = () =>
  import(/* webpackChunkName: "screens.checkout.step-one" */ '@/components/screens/checkout/CheckoutStepOne.vue');
const CheckoutStepTwo = () =>
  import(/* webpackChunkName: "screens.checkout.step-two" */ '@/components/screens/checkout/CheckoutStepTwo.vue');
const CheckoutStepThree = () =>
  import(/* webpackChunkName: "screens.checkout.step-three" */ '@/components/screens/checkout/CheckoutStepThree.vue');

@Component({
  components: {
    Loading,
    CheckoutHeader,
    PageLayout,
    CheckoutUserLogin,
    CheckoutStepOne,
    CheckoutStepTwo,
    CheckoutStepThree
  },
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<Reservation>) {
    next((vm: Reservation) => {
      vm.from = from;
    });
  }
})
export default class Reservation extends Mixins(AuthMixin, RoutingMixin) {
  private car: CarReservation | null = null;
  private from: Route | null = null;
  public loading = true;
  public activeStep = 0;

  /**
   * TODO Update way of fetching car / redirecting
   * When set to "mounted", after going through reservation process with sign in firs time,
   * further attempts end with redirect loop when going from van list to reservation login
   * with "already signed" message
   */
  async mounted() {
    this.activeStep = this.loggedUser === undefined ? 0 : 1;
    await this.checkDates();
    await this.fetchCar();
    this.loading = false;
  }

  @Watch('loggedUser')
  onLoggedUserChange() {
    this.activeStep = this.loggedUser === undefined ? 0 : 1;
  }

  async changeActiveStep(newActiveStep: number) {
    await this.checkDates();
    window.scrollTo(0, 0);
    this.activeStep = newActiveStep;
  }

  async checkDates() {
    const start = this.$route.query.from.toString();
    const end = this.$route.query.to.toString();

    if (!start || !end) {
      this.$toast.error('Začátek nebo konec rezervace nebyl nalezen, vyberte prosím nový termín.');
      this.resetReservationDatesInState();
      return this.navigateToRental();
    }

    if (!moment(start).isValid() || !moment(end).isValid() || moment(start).isAfter(end)) {
      this.$toast.error('Datum začátku nebo konce rezervace je neplatné, vyberte prosím nový termín.');
      this.resetReservationDatesInState();
      return this.navigateToRental();
    }

    if (
      moment(start).isBefore(moment().format(DEFAULT_DATE_FORMAT)) ||
      moment(end).isBefore(moment().format(DEFAULT_DATE_FORMAT))
    ) {
      this.$toast.error('Datum začátku ani konce rezervace nemůže být v minulosti, vyberte prosím nový termín.');
      this.resetReservationDatesInState();
      return this.navigateToRental();
    }
  }

  async fetchCar() {
    let { from, to, car } = this.$route.query;

    if (!from || !to || !car) {
      // return to hp when not dates or carId are provided
      await this.$router.push('/');
      return;
    }

    car = car.toString();
    from = from.toString();
    to = to.toString();

    try {
      if (!(await isCarAvailable(car, from, to))) {
        this.$toast.error('Tato dodávka už bohužel není dostupná, vyberte prosím jinou.');
        this.resetReservationDatesInState();
        return this.navigateToRental();
      }
      this.car = await getCar(car, from, to);
    } catch (e) {
      this.$toast.error((e as Error).message);
      await this.$router.push({ name: HomeRoute });
    }
  }
}
