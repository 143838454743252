import Vue from 'vue';
import App from './App.vue';
import { createRouter } from './router';
import axios from 'axios';

import Vuelidate from 'vuelidate';
import 'vue-toastification/dist/index.css';
import moment from 'moment';
import store from './store';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';

import '@/styles/styles.scss';

import Config from './config';
import { EnvironmentConfig } from './env-config';

type AppProps = {
  beforeApp?: (_: { router: VueRouter; config: EnvironmentConfig }) => Promise<void>;
  afterApp?: (_: { app: Vue; router: VueRouter }) => Promise<void>;
} & (
  | {
      mode: 'server';
      serverConfig: EnvironmentConfig;
    }
  | {
      mode: 'client';
      serverConfig: undefined;
    }
);

export async function createApp({ mode, serverConfig, beforeApp, afterApp }: AppProps) {
  const config: EnvironmentConfig =
    mode === 'server'
      ? serverConfig
      : // @ts-ignore
        window.__CONFIG;
  Config.setConfig(config);

  Vue.config.productionTip = Config.getValue('environment') !== 'production';
  Vue.prototype.__SSR = mode === 'server';

  Vue.use(Vuelidate);
  Vue.use(VueMeta);

  moment.locale('cs');

  // @ts-ignore
  global.File = typeof window === 'undefined' ? Object : window.File;

  // set base url for api calls
  axios.defaults.baseURL = Config.getValue('backendUrl');
  axios.defaults.withCredentials = true;

  Vue.directive('cy-id', {
    bind(el, binding) {
      if (Config.getValue('environment') !== 'production') {
        el.setAttribute('data-cy', binding.value);
      }
    }
  });

  Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
      // @ts-ignore
      el.clickOutsideEvent = function (event: MouseEvent) {
        // here I check that click was outside the el and his children
        // @ts-ignore
        if (!(el == event.target || el.contains(event.target))) {
          // and if it did, call method provided in attribute value
          // @ts-ignore
          vnode.context[binding.expression](event);
        }
      };
      // @ts-ignore
      document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind: function (el) {
      // @ts-ignore
      document.body.removeEventListener('click', el.clickOutsideEvent);
    }
  });

  Vue.filter('formatNumber', function (value: number) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  });
  Vue.filter('toInteger', function (value: number) {
    return Math.floor(value);
  });
  const router = await createRouter();

  beforeApp &&
    (await beforeApp({
      router,
      config
    }));

  const app = new Vue({
    router,
    store,
    render: (h) => h(App)
  });

  const result = {
    app,
    router
  };

  afterApp && (await afterApp(result));

  return result;
}
