import { EnvironmentConfig } from '@/env-config';

class Config {
  private config!: EnvironmentConfig;

  setConfig(config: EnvironmentConfig) {
    this.config = config;
  }

  getValue<T = string | null>(property: keyof EnvironmentConfig): T {
    if (this.config[property] !== undefined) {
      return this.config[property] as unknown as T;
    }

    if (!['prod', 'production'].includes(this.config.environment.toString())) {
      // eslint-disable-next-line
      console.warn(`Property ${property} in config does not exist!`);
    }
    return '' as unknown as T;
  }
}

export default new Config();
