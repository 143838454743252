import axios from 'axios';

export enum FeatureFlag {
  BANK_TRANSFER_ENABLED = 'bank_transfer_enabled',
  SHOW_GTAG_CONSOLE_LOGS = 'show_gtag_console_logs',
  ADDITIONAL_SERVICES_ENABLED = 'additional_services_enabled',
  USE_NEW_HOMEPAGE = 'use_new_homepage',
  IS_RESERVATION_PROCESS_BLOCKED = 'is_reservation_process_blocked'
}

type StrategyScope = {
  id: number;
  environment_scope: string;
};

type Strategy = {
  id: number;
  name: string;
  parameters: Record<string, unknown>;
  scopes: StrategyScope[];
  user_list: unknown;
};

export type GitlabFeatureFlag = {
  name: string;
  description: string;
  active: boolean;
  version: string;
  created_at: string;
  updated_at: string;
  scopes: unknown[];
  strategies: Strategy[];
};

class FeatureFlagService {
  // TODO this could be cached so that we don't fetch it 20x each reservation
  public async getFeatureFlagActiveByName(featureFlag: FeatureFlag): Promise<boolean> {
    const response = await axios.get(`/featureFlag`, {
      params: { name: featureFlag }
    });
    if (!response || !response.data) {
      throw new Error(`Request to fetch the feature flag "${featureFlag}" setting was not successful. Aborting.`);
    }
    if (!response.data.data) {
      throw new Error(`Response of the feature flag fetching request is invalid.`);
    }
    const responseFeatureFlag: GitlabFeatureFlag = response.data.data;
    return responseFeatureFlag.active;
  }
}

export default new FeatureFlagService();
