/* eslint-disable @typescript-eslint/no-explicit-any */

import Vue from 'vue';
import VueRouter from 'vue-router';
import config from '../../config';

declare type PixelAction = 'init' | 'track' | 'trackCustom';

declare global {
  function fbq(action: PixelAction, event: string, args?: any): void;
}

export default function FacebookPixelPlugin(vue: typeof Vue, options: { router: VueRouter }): void {
  if (config.getValue('environment') !== 'production') {
    vue.prototype.$fbq = () => null;
    return;
  }

  // track PageView event
  fbq('track', 'PageView');

  options.router.afterEach(() => {
    fbq('track', 'PageView');
  });

  vue.prototype.$fbq = (action: PixelAction, event: string, args?: any) => fbq(action, event, args);
}

export { PixelAction };
