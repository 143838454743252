








import { Component, Mixins } from 'vue-property-decorator';
import UserPageLayout from '@/components/user/userPageLayout.vue';
import UserAccount from '@/components/user/userAccount.vue';
import { AuthMixin } from '@/mixins/user/Auth.mixin';

@Component({
  components: { UserPageLayout, UserAccount }
})
export default class UserProfile extends Mixins(AuthMixin) {}
