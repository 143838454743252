import { AppliedDiscount } from '@/services/reservations.service';

export interface RootState {
  carsWithAvailability: Array<CarAvailability>;
  carsAvailable: Array<Car>;
  allCars: Array<Car>;
  searchParameters: SearchParams;
  selectedCar: Car | null;
  mouseoverCar: Car | null;
  isCameraHandlerVisible: boolean;
  forceCloseCamera: string;
  cameraCallback: () => void | null;
  loggedUser?: AuthorizedUser;
  fetchingLoggedUser: boolean;
  subscribesToNewsletter: boolean;
  reservationStartDate: string;
  reservationEndDate: string;
  reservationStartDisableDates: DisableDates;
  reservationEndDisableDates: DisableDates;
  showCookieSettings: boolean;
  carTypeUrl: string;
  cityUrl: string;
  reservationCheckoutLoading: boolean;
  activeDiscount: AppliedDiscount | null;
  documentsValid: boolean;
  carQuerySlugs: string[];
  parkingQuerySlugs: string[];
  eligiblePrimaryParams: string[];
  vehicleFilters: FilterOption[];
  parkingFilters: FilterOption[];
  googleRating: number;
}

interface ParkingLocation {
  lat: number;
  lng: number;
}

export interface CarImage {
  small: string;
  large: string;
}

export interface Car {
  _id: string;
  name: string;
  parkingLocation: ParkingLocation;
  parameters: CarParameter[];
  locationDescription: string;
  parkingArea: GeoJSON.FeatureCollection;
  currentLocation: {
    lat: string;
    lng: string;
  };
  images: CarImage[];
  licensePlate: string;
  brand: string;
  description: string;
  overallPrice: number;
  vehicleType: string;
  vehicleTypeCode: string;
  urlSlug: string;
  allowedZone: AllowedZones;
  city: string;
}

export interface CarReservation {
  _id: string;
  name: string;
  parameters: CarParameter[];
  locationDescription: string;
  parkingArea: GeoJSON.FeatureCollection;
  images: CarImage[];
  licensePlate: string;
  brand: string;
  description: string;
  overallPrice: number;
}

export interface CarAvailability {
  car: Car;
  firstDateAvailable: Date;
  isAvailable: boolean;
  isBusy: boolean;
  isDisabled: boolean;
}

export interface FormData {
  fullname: string;
  phone: string;
  isCompany: boolean;
  companyName?: string;
  companyRegistrationNumber?: string;
  companyVATNumber?: string;
  companyAddressStreet?: string;
  companyAddressCity?: string;
  companyAddressZip?: string;
  addressStreet: string;
  addressCity: string;
  addressZip: string;
  subscribesToNewsletter: boolean;
  roles?: string[];
  driverName?: string;
}

export interface CheckoutStepTwoFormData {
  driverName: string;
}

export interface CarParameter {
  value: string;
  key: string;
  description?: string;
  inCheckoutSummary?: boolean;
}

interface SearchParams {
  start: string | null;
  end: string | null;
}

export interface AuthorizedUser {
  id: string;
  email: string;
  onBlacklist: boolean;
  user: FormData;
}

export enum UserDocumentType {
  personalIdFront = 'personalIdFront',
  personalIdBack = 'personalIdBack',
  personalIdSelfie = 'personalIdSelfie',
  drivingLicenseFront = 'drivingLicenseFront',
  drivingLicenseBack = 'drivingLicenseBack'
}

export type UserDocuments = {
  [key: string]: UserDocument;
};

export type UserDocument = {
  name: string;
  path: string;
  status: UserDocumentStatus;
  expiration: Date | null;
  properties: {
    blurriness: number;
  };
};

type UserDocumentStatus = 'valid' | 'pending' | 'expired';

export type UserDocumentFormData = {
  personalIdFront: File | null;
  personalIdBack: File | null;
  personalIdSelfie: File | null;
  drivingLicenseFront: File | null;
  drivingLicenseBack: File | null;
  [key: string]: File | null;
};

interface DisableDates {
  from: Date;
  to: Date;
}

export enum AllowedZones {
  CR = 'CR',
  Europe = 'Europe'
}

export interface VehicleType {
  vehicleTypeValue: string;
  vehicleSubTypeValues?: string[];
}

export interface FilterOption {
  parentValue: string;
  parentPathSlug: string;
  parentQuerySlug?: string;
  subValues?: FilterSubValue[];
}
export interface FilterSubValue {
  value: string;
  slug: string;
}

export const ALLOWED_ZONE_KEY = 'Kilometry v ceně';
