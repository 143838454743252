


















import { Component, Vue, Watch } from 'vue-property-decorator';
import store from '../store';
import Webcam from 'webcam-easy';

@Component({
  components: {}
})
export default class CameraHandler extends Vue {
  private webcam!: Webcam;

  get isVisible() {
    return store.state.isCameraHandlerVisible;
  }

  get cameraCallback() {
    return store.state.cameraCallback;
  }

  get forceCloseCamera() {
    return store.state.forceCloseCamera;
  }

  @Watch('isVisible')
  onVisibleChanged(visible: string) {
    if (visible) {
      setTimeout(async () => {
        this.webcam = new Webcam(this.$refs.webcam as Element, 'user', this.$refs.webcamCanvas as Element);
        await this.webcam.start();
      });
    }
  }

  async takeSnap() {
    // @ts-ignore
    this.cameraCallback(await this.flipImage(this.webcam.snap()));
    this.close();
  }

  async flipImage(imgData: string) {
    return new Promise((resolve) => {
      const img = new Image();
      const flipCanvas = this.$refs.flipCanvas as HTMLCanvasElement;
      img.onload = function () {
        flipCanvas.width = img.width;
        flipCanvas.height = img.height;
        const ctx = flipCanvas.getContext('2d');
        if (ctx) {
          ctx.scale(-1, 1);
          ctx.drawImage(img, -img.width, 0);
        }
        resolve(flipCanvas.toDataURL());
      };
      img.src = imgData;
    });
  }

  @Watch('forceCloseCamera')
  close() {
    this.webcam.stop();
    store.dispatch('closeCamera');
  }
}
