




































































































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import Heading from '@/components/checkout/layout/page/Heading.vue';
import Alert from '@/components/ui/Alert.vue';
import { AlertCircleIcon, ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons';
import PhotoField from '@/components/ui/profile/PhotoField.vue';
import DocumentsOverview from '@/components/ui/profile/DocumentsOverview.vue';
import Button from '@/components/ui/Button.vue';
import CheckboxField from '@/components/ui/form/CheckboxField.vue';
import { ButtonColor } from '@/components/ui/Button';
import {
  emptyUserDocumentFormData,
  existingUserDocumentsEditMode
} from '@/components/screens/checkout/CheckoutStepTwo';
import UserService from '@/services/user.service';
import { UserDocument, UserDocumentFormData, UserDocuments, UserDocumentType } from '@/store/types';
import { ConditionsRoute } from '@/router/routes';
import { Location } from 'vue-router';
import moment from 'moment';
import { userDocumentPairs } from '@/utils/consts';
import TextField from '@/components/ui/form/TextField.vue';
import { required } from 'vuelidate/lib/validators';
import Loading from '@/components/ui/Loading.vue';
import store from '@/store';
import { GtagMixin } from '@/mixins/googleAnalytics/gtag.mixin';

function isDocumentValid(
  value: File | null,
  documentType: string,
  isPersonalIdGroup: boolean,
  userDocumentsComponent: UserDocs
) {
  if (userDocumentsComponent.userHasAnyDocuments) {
    if (
      (isPersonalIdGroup && userDocumentsComponent.arePersonalIdDocumentsExpired) ||
      (!isPersonalIdGroup && userDocumentsComponent.areDrivingLicenseDocumentsExpired)
    ) {
      return value !== null;
    }
    if (userDocumentsComponent.existingUserDocumentsEditMode[documentType]) {
      return value !== null;
    }
    return true;
  } else {
    return value !== null;
  }
}

function isPersonalIdFrontDocumentValid(value: File | null) {
  // @ts-ignore
  return isDocumentValid(value, 'personalIdFront', true, this as UserDocs);
}

function isPersonalIdBackDocumentValid(value: File | null) {
  // @ts-ignore
  return isDocumentValid(value, 'personalIdBack', true, this as UserDocs);
}

function isPersonalIdSelfieDocumentValid(value: File | null) {
  // @ts-ignore
  return isDocumentValid(value, 'personalIdSelfie', true, this as UserDocs);
}

function isDrivingLicenseFrontDocumentValid(value: File | null) {
  // @ts-ignore
  return isDocumentValid(value, 'drivingLicenseFront', false, this as UserDocs);
}

function isDrivingLicenseBackDocumentValid(value: File | null) {
  // @ts-ignore
  return isDocumentValid(value, 'drivingLicenseBack', false, this as UserDocs);
}

@Component({
  components: {
    Loading,
    PhotoField,
    DocumentsOverview,
    Alert,
    Heading,
    AlertCircleIcon,
    Button,
    ChevronLeftIcon,
    ChevronRightIcon,
    CheckboxField,
    TextField
  },
  validations: {
    newUserDocuments: {
      personalIdFront: { isPersonalIdFrontDocumentValid },
      personalIdBack: { isPersonalIdBackDocumentValid },
      personalIdSelfie: { isPersonalIdSelfieDocumentValid },
      drivingLicenseFront: { isDrivingLicenseFrontDocumentValid },
      drivingLicenseBack: { isDrivingLicenseBackDocumentValid }
    },
    form: {
      driverName: {
        required
      }
    }
  }
})
export default class UserDocs extends Mixins(GtagMixin) {
  @Prop() readonly isUserProfile: boolean | undefined;

  private newUserDocuments: UserDocumentFormData = emptyUserDocumentFormData;
  public existingUserDocuments: UserDocuments = {};
  public existingUserDocumentsEditMode = existingUserDocumentsEditMode;
  UserDocumentType = UserDocumentType;

  public photosLoading: Record<UserDocumentType, boolean> = {
    personalIdFront: false,
    personalIdBack: false,
    personalIdSelfie: false,
    drivingLicenseFront: false,
    drivingLicenseBack: false
  };

  public personalIdOverview = true;
  public drivingLicenseOverview = true;

  public fetching = false;
  private uploading = false;

  public buttonColor: ButtonColor = 'light';

  public termsAndConditionsAccepted = false;

  public termsAndConditionsAcceptedErrorMessage = '';

  public changesDoneInUserProfile = false;

  public form = {
    driverName: ''
  };

  getDocumentPath(existingUserDocument: UserDocument): string | null {
    return existingUserDocument ? existingUserDocument.path : null;
  }

  get isReadyToProceed() {
    return (
      this.form.driverName &&
      this.termsAndConditionsAccepted &&
      ((this.personalIdOverview && this.drivingLicenseOverview) ||
        (this.userHasAllDrivingLicenseDocuments && this.userHasAllPersonalIdDocuments))
    );
  }

  get isLoading(): boolean {
    return this.uploading || this.areAnyPhotosLoading || this.fetching;
  }

  get queryFrom(): string {
    return this.$route.query.from.toString() ?? '';
  }

  get queryTo(): string {
    return this.$route.query.to.toString() ?? '';
  }

  get userHasAnyDocuments(): boolean {
    return Object.keys(this.existingUserDocuments).length > 0;
  }

  get userHasAllPersonalIdDocuments(): boolean {
    return [UserDocumentType.personalIdFront, UserDocumentType.personalIdBack, UserDocumentType.personalIdSelfie].every(
      (doc) => {
        return doc in this.existingUserDocuments;
      }
    );
  }

  get userHasAllDrivingLicenseDocuments(): boolean {
    return [UserDocumentType.drivingLicenseFront, UserDocumentType.drivingLicenseBack].every((doc) => {
      return doc in this.existingUserDocuments;
    });
  }

  get personalIdDocuments(): UserDocument[] {
    if (!this.userHasAnyDocuments) {
      return [];
    }
    return [
      this.existingUserDocuments.personalIdFront,
      this.existingUserDocuments.personalIdBack,
      this.existingUserDocuments.personalIdSelfie
    ].filter((el) => el);
  }

  get personalIdDocumentsExpiration(): string {
    return this.getExpirationOfUserDocuments(this.personalIdDocuments);
  }

  get arePersonalIdDocumentsExpired(): boolean {
    return this.areUserDocumentsExpired(this.personalIdDocuments);
  }

  get drivingLicenseDocuments(): UserDocument[] {
    if (!this.userHasAnyDocuments) {
      return [];
    }
    return [this.existingUserDocuments.drivingLicenseFront, this.existingUserDocuments.drivingLicenseBack].filter(
      (el) => el
    );
  }

  get drivingLicenseDocumentsExpiration(): string {
    return this.getExpirationOfUserDocuments(this.drivingLicenseDocuments);
  }

  get areDrivingLicenseDocumentsExpired(): boolean {
    return this.areUserDocumentsExpired(this.drivingLicenseDocuments);
  }

  get termsAndConditionsLink(): Location {
    return { name: ConditionsRoute };
  }

  get areAnyPhotosLoading(): boolean {
    return Object.values(this.photosLoading).some((value) => value);
  }

  private docsStatuses() {
    const documentsValid = !(
      Object.keys(this.existingUserDocumentsEditMode).find(
        (docType) => this.existingUserDocumentsEditMode[docType] === true
      ) ||
      Object.keys(this.existingUserDocuments).find((docType) => this.existingUserDocuments[docType].status !== 'valid')
    );
    store.dispatch('setDocumentsValid', documentsValid);
  }

  async mounted(): Promise<void> {
    this.fetching = true;

    [this.existingUserDocuments, this.form.driverName] = await Promise.all([
      UserService.getDocuments(),
      UserService.getDriver()
    ]);

    this.parseIncomingDocuments();
    this.personalIdOverview = this.userHasAllPersonalIdDocuments;
    this.drivingLicenseOverview = this.userHasAllDrivingLicenseDocuments;
    this.fetching = false;
  }

  parseIncomingDocuments() {
    this.renameDocuments(this.existingUserDocuments);
    for (const doc in UserDocumentType) {
      // chceme jen aby ty, co chybej, sly do editmodu
      this.existingUserDocumentsEditMode[doc] = !this.existingUserDocuments[doc];
    }
  }

  renameDocuments(existingUserDocuments: UserDocuments) {
    for (const pair of userDocumentPairs) {
      if (existingUserDocuments[pair[0]]) {
        const extension = existingUserDocuments[pair[0]].name.split('.').pop();
        existingUserDocuments[pair[0]].name = `${pair[1]}.${extension}`;
      }
    }
  }

  getExpirationOfUserDocuments(userDocuments: UserDocument[]) {
    if (
      !this.areUserDocumentsExpired(userDocuments) &&
      (!userDocuments.length || userDocuments.some((doc) => !doc.expiration))
    ) {
      return '';
    }
    const earliestExpirationDocument = userDocuments.reduce((earliestDoc: UserDocument, currentDoc: UserDocument) => {
      if (currentDoc.expiration === null) return earliestDoc;

      const currentDocDate = moment(currentDoc.expiration);

      if (!earliestDoc.expiration || currentDocDate.isBefore(moment(earliestDoc.expiration))) {
        return currentDoc;
      }

      return earliestDoc;
    });
    return moment(earliestExpirationDocument.expiration).format('D. M. YYYY');
  }

  areUserDocumentsExpired(userDocuments: UserDocument[]) {
    if (!userDocuments.length) {
      return false;
    }
    return userDocuments.some((doc) => doc.status === 'expired');
  }

  getUserDocumentExpiration(document: UserDocumentType) {
    return this.existingUserDocuments[document]?.expiration;
  }

  switchPersonalIdDocumentsToEdit(): void {
    this.personalIdOverview = false;
  }

  switchDrivingLicenseDocumentsToEdit(): void {
    this.drivingLicenseOverview = false;
  }

  switchUserDocumentToEditMode(userDocument: string): void {
    this.newUserDocuments[userDocument] = null;
    this.existingUserDocumentsEditMode[userDocument] = true;
  }

  isUserDocumentInvalid(field: string): boolean {
    const validation = this.$v.newUserDocuments[field];
    if (validation === undefined) {
      return false;
    }
    return validation.$error;
  }

  changeButtonColor() {
    this.buttonColor = 'primary';
  }

  goBack(): void {
    this.$emit('changeActiveStep', 1);
  }

  async saveDriver() {
    try {
      this.uploading = true;
      await UserService.updateUserDocsInformation({ driverName: this.form.driverName });
      if (!this.isUserProfile) {
        this.$emit('changeActiveStep', 3);
        this.triggerGtagEvent('reservation-step2-submit-driver-data.button', 'click');
      } else {
        this.buttonColor = 'light';
        this.$toast.success('Data byla úspěšně aktualizována.');
      }
      this.changesDoneInUserProfile = false;
    } catch (e) {
      this.$toast.error('Něco se nepodařilo, zkuste to prosím později.');
    } finally {
      if (store.state.isCameraHandlerVisible) {
        await store.dispatch('forceCloseCamera');
      }
      this.uploading = false;
      this.docsStatuses();
    }
  }

  async proceed(): Promise<void> {
    if (!this.termsAndConditionsAccepted) {
      this.termsAndConditionsAcceptedErrorMessage = 'Bez souhlasu s tímto bodem Vám bohužel nemůžeme půjčit dodávku.';
      return;
    }

    if (!this.validateAllDocumentsUploaded()) {
      return;
    }
    await this.saveDriver();
  }

  async saveButton(): Promise<void> {
    await this.saveDriver();
  }

  validateAllDocumentsUploaded(): boolean {
    if (this.$v.$invalid || this.$v.form.$invalid) {
      this.$toast.error('Nahrajte, prosím, všechny dokumenty');
      return false;
    }
    return true;
  }

  validationMessage(field: string): string | null {
    const validation = this.$v.form[field];
    if (!validation || !validation.$error) {
      return null;
    }

    switch (field) {
      case 'driverName':
        if (!validation.required) {
          return 'Vyplňte jméno řidiče.';
        }
    }

    return null;
  }

  async uploadImage(document: UserDocumentFormData) {
    const key = Object.keys(document)[0] as UserDocumentType;
    this.photosLoading[key] = true;
    this.changesDoneInUserProfile = true;
    try {
      const res = await UserService.updateDocuments(document);
      this.existingUserDocuments = { ...this.existingUserDocuments, ...res };
      this.parseIncomingDocuments();
    } catch (err) {
      this.$toast.error((err as Error).message);
      console.error(err);
    } finally {
      this.photosLoading[key] = false;
    }
  }

  driverNameChanged() {
    this.changeButtonColor();
    this.changesDoneInUserProfile = true;
  }
}
