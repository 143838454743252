import axios, { AxiosError } from 'axios';
import { CarReservation } from '@/store/types';
import { post } from '@/utils/api/client';
import { HttpError } from '@/utils/api/errors';
import { ParkingPickupForm } from '@/models/pickupForm';
import { fileToBase64 } from '@/utils/file';

export interface ReservationData {
  paymentMethod: string;
  carId: string | undefined;
  start: string | null;
  end: string | null;
  subscribesToNewsletter: boolean;
  additionalServices: string[];
  paymentInstrumentExternalId: string;
  stripeHidden?: boolean;
  discountCode?: string;
}

export interface Reservation {
  overallPrice: number;
  start?: string;
  end?: string;
  carId?: string;
  returnForm: ReturnForm;
  pickupForm: PickupForm;
  isPaid?: boolean;
  paymentMethod?: 'card' | 'transfer';
  active?: boolean;
  subscribesToNewsletter?: boolean;
  inInvoicer: boolean;
}

export interface ReservationPaymentStatus {
  reservation: {
    id: string;
    userId: string;
  };
  paymentMethod: 'card' | 'transfer';
  recentPayment: {
    paymentStatus: 'PAID' | 'PROCESSING' | 'CANCELLED';
    paymentInstrumentExternalId: number;
    active: boolean;
  } | null;
}

export interface RegeneratedPayment {
  paymentUrl: string;
}

export type FormImage = {
  path: File | string;
  properties: {
    blurriness: number;
  };
};

export interface ReturnFormImages extends Record<string, FormImage | string | number | File | null> {
  // when uploading, it turns into a file that is sent to BE - when fetching, we fetch URLs, that we then send to BE to fetch the image, hence the string
  outsidePhotos1: FormImage | null;
  outsidePhotos2: FormImage | null;
  outsidePhotos3: FormImage | null;
  outsidePhotos4: FormImage | null;
  insidePhotos1: FormImage | null;
  insidePhotos2: FormImage | null;
  insidePhotos3: FormImage | null;
  additionalPhotos1: FormImage | null;
  additionalPhotos2: FormImage | null;
  additionalPhotos3: FormImage | null;
  additionalPhotos4: FormImage | null;
}
export interface PickupFormImages extends Record<string, FormImage | string | number | File | null> {
  outsidePhotos1: FormImage | null;
  outsidePhotos2: FormImage | null;
  outsidePhotos3: FormImage | null;
  outsidePhotos4: FormImage | null;
  insidePhotos1: FormImage | null;
  insidePhotos2: FormImage | null;
  insidePhotos3: FormImage | null;
  additionalPhotosOutside1: FormImage | null;
  additionalPhotosOutside2: FormImage | null;
  additionalPhotosOutside3: FormImage | null;
  additionalPhotosOutside4: FormImage | null;
  additionalPhotosInside1: FormImage | null;
  additionalPhotosInside2: FormImage | null;
  additionalPhotosInside3: FormImage | null;
  additionalPhotosInside4: FormImage | null;
}

export type ReturnFormImageKey =
  | 'outsidePhotos1'
  | 'outsidePhotos2'
  | 'outsidePhotos3'
  | 'outsidePhotos4'
  | 'insidePhotos1'
  | 'insidePhotos2'
  | 'insidePhotos3'
  | 'additionalPhotos1'
  | 'additionalPhotos2'
  | 'additionalPhotos3'
  | 'additionalPhotos4';

export type PickupFormImageKey =
  | 'outsidePhotos1'
  | 'outsidePhotos2'
  | 'outsidePhotos3'
  | 'outsidePhotos4'
  | 'insidePhotos1'
  | 'insidePhotos2'
  | 'insidePhotos3'
  | 'additionalPhotosOutside1'
  | 'additionalPhotosOutside2'
  | 'additionalPhotosOutside3'
  | 'additionalPhotosOutside4'
  | 'additionalPhotosInside1'
  | 'additionalPhotosInside2'
  | 'additionalPhotosInside3'
  | 'additionalPhotosInside4';

export interface ReturnForm extends ReturnFormImages {
  parkingOnSamePlace: string;
  parkingOnSamePlaceOther: string;
  noOutsideDamage: string;
  noOutsideDamageOther: string;
  lightsOffDoorsLocked: string;
  lightsOffDoorsLockedOther: string;
  noInsideDamage: string;
  noInsideDamageOther: string;
  fullTank: string;
  fullTankOther: string;
  keysInsideBox: string;
  keysInsideBoxOther: string;
  documentsInsideBox: string;
  documentsInsideBoxOther: string;
  comments: string;
  mileage: number | null;
}
export interface PickupForm extends PickupFormImages {
  noOutsideDamage: string;
  noOutsideDamageOther: string;
  noInsideDamage: string;
  noInsideDamageOther: string;
  fullTank: string;
  fullTankOther: string;
  documentsInsideBox: string;
  documentsInsideBoxOther: string;
  comments: string;
  mileage: number | null;
}

export interface ReturnFormPageData {
  returnForm: ReturnForm;
  pin: string;
  end: Date;
  carName: string;
  carId: string;
  carLastKnownMileage: number;
  fuelTankCapacityInLitres: number;
  fuelTankInLitres?: number;
}
export interface PickupFormPageData {
  pickupForm: PickupForm;
  pin: string;
  start: Date;
  end: Date;
  carName: string;
  carId: string;
  carLicensePlate: string;
  carParkingId: string;
  carCurrentLocation: {
    lat: number;
    lng: number;
  };
  parking: ParkingPickupForm;
  carLastKnownMileage: number;
  fuelTankCapacityInLitres: number;
  fuelTankInLitres?: number;
}

interface PaymentData {
  client_secret: string;
}

interface AccountedEntity {
  id: number;
}

interface AccountedEntityCharge {
  paymentGatewayUrl: string | null;
  paymentData?: PaymentData;
  id: number;
}

export interface CompletedRegistration {
  accountedEntityCharge: AccountedEntityCharge;
  accountedEntity: AccountedEntity;
  id: string;
  overallPrice: number;
  customerId: number;
}

export type ReservationForMap = Pick<Reservation, 'start' | 'end' | 'active' | 'carId'>;

// send registration request and make reservation
const makeReservation = async (data: ReservationData): Promise<CompletedRegistration> => {
  return (
    await post('/reservations/create', data, {
      defaultError: 'Nepodařilo se vytvořit rezervaci.'
    })
  ).data;
};

// get car detail from api
const getCar = async (id: string, from: string, to: string): Promise<CarReservation> => {
  try {
    const { data } = await axios.get(`/car/get-for-reservation?id=${id}&start=${from}&end=${to}`);
    return {
      _id: data.data._id,
      name: data.data.name,
      images: data.data.images,
      parameters: data.data.parameters,
      locationDescription: data.data.locationDescription,
      parkingArea: data.data.parkingArea,
      licensePlate: data.data.licensePlate,
      brand: data.data.brand,
      description: data.data.description,
      overallPrice: data.data.overallPrice
    };
  } catch (e) {
    throw e;
  }
};

// check if car is still available
const isCarAvailable = async (id: string, from: string, to: string): Promise<boolean> => {
  try {
    const { data } = await axios.get(`/car/${id}/check-availability?start=${from}&end=${to}`);
    return data.available === true;
  } catch (err) {
    const e = err as AxiosError;
    const { message } = e.response?.data;
    if (message) {
      throw new Error(message);
    } else {
      throw new Error('Nepodařilo se zkontrolovat dostupnost dodávky.');
    }
  }
};

const getReservationPaymentStatus = async (id: string, blowfish?: string): Promise<ReservationPaymentStatus | null> => {
  try {
    const { data } = await axios.get(`/reservations/${id}/payment-status`, {
      headers: blowfish
        ? {
            'x-reservation-token': blowfish
          }
        : undefined
    });
    return data.data;
  } catch (err) {
    const e = err as AxiosError;
    if (e.response) {
      const {
        data: { message },
        status
      } = e.response;
      throw new HttpError(message || 'Request failed', status || 500);
    }
    throw new Error('Reaching reservation payment status failed');
  }
};

const getReservationForMap = async (id: string): Promise<ReservationForMap> => {
  try {
    const { data } = await axios.get(`/reservations/${id}/map`);
    return data.data;
  } catch (err) {
    const e = err as AxiosError;
    const { message } = e.response?.data;
    if (message) {
      throw new Error(message);
    } else {
      throw new Error('Nepodařilo se získat data o rezervaci.');
    }
  }
};

const getReservationReturnFormPageData = async (id: string): Promise<ReturnFormPageData> => {
  try {
    const { data } = await axios.get(`/reservations/${id}/return`);
    return data;
  } catch (err) {
    const e = err as AxiosError;
    const { message } = e.response?.data;
    if (message) {
      throw new Error(message);
    } else {
      throw new Error('Nepodařilo se získat data pro návratový formulář.');
    }
  }
};

const getReservationPickupFormPageData = async (id: string): Promise<PickupFormPageData> => {
  try {
    const { data } = await axios.get(`/reservations/${id}/pickup`);
    return data;
  } catch (err) {
    const e = err as AxiosError;
    const { message } = e.response?.data;
    if (message) {
      throw new Error(message);
    } else {
      throw new Error('Nepodařilo se získat data pro vyzvedávací formulář.');
    }
  }
};

const sendReturnForm = async (id: string, data: ReturnForm, fuelTankInLitres: number): Promise<void> => {
  try {
    await axios.post(`/reservations/${id}/return`, { ...data, fuelTankInLitres });
  } catch (err) {
    const e = err as AxiosError;
    const { message } = e.response?.data;
    if (message) {
      throw new Error(message);
    } else {
      throw new Error('Nepodařilo se odeslat návratový formulář.');
    }
  }
};

const sendPickupForm = async (id: string, data: PickupForm, fuelTankInLitres: number): Promise<void> => {
  try {
    await axios.post(`/reservations/${id}/pickup`, { ...data, fuelTankInLitres });
  } catch (err) {
    const e = err as AxiosError;
    const { message } = e.response?.data;
    if (message) {
      throw new Error(message);
    } else {
      throw new Error('Nepodařilo se odeslat vyzvedávací formulář.');
    }
  }
};

const updateReturnForm = async (id: string, data: Partial<ReturnForm>): Promise<void> => {
  try {
    await axios.patch(`/reservations/${id}/return`, data);
  } catch (err) {
    const e = err as AxiosError;
    const { message } = e.response?.data;
    if (message) {
      throw new Error(message);
    } else {
      throw new Error('Nepodařilo se odeslat návratový formulář.');
    }
  }
};

const updatePickupForm = async (id: string, data: Partial<PickupForm>): Promise<void> => {
  try {
    await axios.patch(`/reservations/${id}/pickup`, data);
  } catch (err) {
    const e = err as AxiosError;
    const { message } = e.response?.data;
    if (message) {
      throw new Error(message);
    } else {
      throw new Error('Nepodařilo se odeslat upravený vyzvedávací formulář.');
    }
  }
};

const sendPickupFormImage = async (id: string, image: PickupFormImages): Promise<PickupFormImages> => {
  const postData: {
    [key: string]: {
      [key: string]: string;
    };
  } = {};
  for (const [key, document] of Object.entries(image as Record<string, File>)) {
    if (document === null) {
      continue;
    }
    postData[key] = {
      name: document.name || Date.now().toString(),
      content: await fileToBase64(document)
    };
  }
  try {
    return (await axios.post(`/reservations/${id}/pickup-image`, postData)).data.data;
  } catch (err) {
    const e = err as AxiosError;
    const { message } = e.response?.data;
    if (message) {
      throw new Error(message);
    } else {
      throw new Error('Nepodařilo se nahrát obrázek.');
    }
  }
};

const sendReturnFormImage = async (id: string, image: ReturnFormImages): Promise<Partial<ReturnFormImages>> => {
  const postData: {
    [key: string]: {
      [key: string]: string;
    };
  } = {};
  for (const [key, document] of Object.entries(image as Record<string, File>)) {
    if (document === null) {
      continue;
    }
    postData[key] = {
      name: document.name || Date.now().toString(),
      content: await fileToBase64(document)
    };
  }
  try {
    return (await axios.post(`/reservations/${id}/return-image`, postData)).data.data;
  } catch (err) {
    const e = err as AxiosError;
    const { message } = e.response?.data;
    if (message) {
      throw new Error(message);
    } else {
      throw new Error('Nepodařilo se nahrát obrázek.');
    }
  }
};

export interface AppliedDiscount {
  description: string;
  priceDiscountedByAmount: number;
  code: string;
}

interface ValidateDiscountCodeResponse {
  discountCode: {
    code: string;
    description: string;
    discountedAmount: number; // This is always multiplied by 100!
    type: 'absolute';
  };
  priceDiscountedByAmount: number;
}

export const validateEligibleDiscountCode = async (
  from: string,
  to: string,
  carId: string,
  discountCode: string
): Promise<AppliedDiscount> => {
  const { data } = await axios.post<ValidateDiscountCodeResponse>(`/reservations/applicable-discount`, {
    from,
    to,
    carId,
    discountCode
  });
  return {
    description: data.discountCode.description,
    priceDiscountedByAmount: data.priceDiscountedByAmount,
    code: discountCode
  };
};

export {
  makeReservation,
  getCar,
  isCarAvailable,
  sendReturnForm,
  sendPickupForm,
  sendReturnFormImage,
  sendPickupFormImage,
  getReservationPaymentStatus,
  getReservationReturnFormPageData,
  getReservationPickupFormPageData,
  getReservationForMap,
  updateReturnForm,
  updatePickupForm
};
