






























import { Component, Mixins } from 'vue-property-decorator';
import UserService from '@/services/user.service';
import store from '@/store';
import { AuthMixin } from '@/mixins/user/Auth.mixin';

@Component({
  components: {}
})
export default class UserNavigation extends Mixins(AuthMixin) {
  public async logout() {
    try {
      if (this.$gAuth.isAuthorized) {
        await this.$gAuth.signOut();
      }
      await UserService.logout();
      await store.dispatch('fetchLoggedUser');
      this.$toast.success('A jste odhlášen!');
    } catch (e) {
      this.$toast.error((e as Error).message);
    }
  }
}
