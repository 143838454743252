import axios from 'axios';
import { CarPriceListInfo } from '@/models/carsPricelist';
import { CarCurrentLocation, CarLocations } from '@/models/cars';
import { CarAvailability, Car } from '@/store/types';
import { AvailableFiltersResponse } from './model/car';

class CarService {
  public async searchCar(parameters: object): Promise<CarAvailability[]> {
    const queryParams = parameters !== undefined && parameters !== null ? parameters : {};
    const response = await axios.get('/car/free', {
      params: queryParams
    });
    return response.data.data;
  }

  public async getCarsPriceList(section?: string): Promise<CarPriceListInfo[]> {
    const response = await axios.get('/car/price-list', { params: { section } });
    return response.data.data;
  }

  public async allCars() {
    const response = await axios.get('/car/all');
    return response.data.data;
  }

  public async getCarById(carId: string, admin = false): Promise<Car | null> {
    const response = await axios.get('/car/get', {
      params: {
        id: carId,
        admin
      }
    });
    return response.data.data;
  }

  public async allCarsWithCurrentLocation(): Promise<CarCurrentLocation[]> {
    const response = await axios.get<{ cars: CarCurrentLocation[] }>('/car/current-locations');
    return response.data.cars;
  }

  // resID is sent simply for security reasons, we only want to allow to fetch curr location if you are the owner of ongoing reservation
  public async getCarLocations(carId: string, reservationId: string): Promise<CarLocations | null> {
    return (await axios.get(`/car/${carId}/locations?reservationId=${reservationId}`)).data.data;
  }

  public async isOnParking(carId: string): Promise<boolean> {
    // Current timestamp is used to force the browser to send the request (especially in the case of a phone with the data saver enabled).
    return (await axios.get(`/car/${carId}/on-parking?ts=${Date.now()}`)).data.onParking;
  }

  public async getAvailableFilters(): Promise<AvailableFiltersResponse> {
    return (await axios.get('/car/available-filters')).data.data;
  }
}

export default new CarService();
