import { Component, Vue } from 'vue-property-decorator';
import { minLength, required, requiredIf } from 'vuelidate/lib/validators';
import { FormData } from '@/store/types';

/**
 * Checks the validity of the zip code (either 11111 or 111 11),
 * blank string accepted as that is handled by the requiredIf function
 * @returns boolean
 */
const isValidZip = (value: string) =>
  /^[\s\d]*\d[\s\d]*\d[\s\d]*\d\s*(?:\d[\s\d]*\d)?[\s\d]*$/.test(value) || value === '';
const isValidPhoneNumber = (value: string) => /^(\+420|\+421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3,4}$/.test(value);
const isValidStreet = (value: string) => /^.*[a-zA-Z]+.*$/.test(value) || value === '';
const isValidCity = (value: string) => /^.*[a-zA-Z]+.*$/.test(value) || value === '';
// Company Related Custom Validators
const isValidICO = (value: string, nestedModel: FormData) => {
  if (!nestedModel.isCompany) {
    return true; // Skip validation if isCompany is false
  }
  // Match string with 8 digits and any number of spaces
  return /^(?=(?:\s*\d\s*){8}$)[\s\d]+$/.test(value);
};
const isValidCompanyStreet = (value: string, nestedModel: FormData) => {
  if (!nestedModel.isCompany) {
    return true; // Skip validation if isCompany is false
  }
  return /^.*[a-zA-Z]+.*$/.test(value);
};
const isValidCompanyCity = (value: string, nestedModel: FormData) => {
  if (!nestedModel.isCompany) {
    return true; // Skip validation if isCompany is false
  }
  return /^.*[a-zA-Z]+.*$/.test(value);
};
const isValidCompanyZip = (value: string, nestedModel: FormData) => {
  if (!nestedModel.isCompany) {
    return true; // Skip validation if isCompany is false
  }
  // Spaces on random spots are ok
  return /^[\s\d]*\d[\s\d]*\d[\s\d]*\d\s*(?:\d[\s\d]*\d)?[\s\d]*$/.test(value);
};
const isValidVAT = (value: string, nestedModel: FormData) => {
  if (!nestedModel.isCompany) {
    return true; // Skip validation if isCompany is false
  }
  if (!value) {
    return true; // Skip validation if VAT number not entered (it is an optional field)
  }
  return /^[\s]*[A-Z][\s]*[A-Z][\s]*(?:\d[\s\d]*){8,10}[\s]*$/.test(value); // Otherwise Validate VAT format if VAT is entered
};

export const emptyFormData: FormData = {
  fullname: '',
  phone: '',
  isCompany: false,
  addressCity: '',
  addressStreet: '',
  addressZip: '',
  subscribesToNewsletter: false
};

@Component({
  validations: {
    form: {
      fullname: { required },
      phone: {
        required,
        minLength: minLength(9),
        isValidPhoneNumber
      },
      addressStreet: {
        required: requiredIf(function (nestedModel) {
          return !nestedModel.isCompany;
        }),
        isValidStreet
      },
      addressCity: {
        required: requiredIf(function (nestedModel) {
          return !nestedModel.isCompany;
        }),
        isValidCity
      },
      addressZip: {
        required: requiredIf(function (nestedModel) {
          return !nestedModel.isCompany;
        }),
        isValidZip
      },
      // COMPANY RELATED VALIDATIONS
      companyName: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.isCompany;
        })
      },
      companyRegistrationNumber: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.isCompany;
        }),
        isValidICO(value: string) {
          return isValidICO(value, this.$v.form.$model);
        }
      },
      companyVATNumber: {
        isValidVAT(value: string) {
          return isValidVAT(value, this.$v.form.$model);
        }
      },
      companyAddressStreet: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.isCompany;
        }),
        isValidCompanyStreet(value: string) {
          return isValidCompanyStreet(value, this.$v.form.$model);
        }
      },
      companyAddressCity: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.isCompany;
        }),
        isValidCompanyCity(value: string) {
          return isValidCompanyCity(value, this.$v.form.$model);
        }
      },
      companyAddressZip: {
        required: requiredIf(function (nestedModel) {
          return nestedModel.isCompany;
        }),
        isValidCompanyZip(value: string) {
          return isValidCompanyZip(value, this.$v.form.$model);
        }
      }
    }
  }
})
export class CheckoutStepOneFormMixin extends Vue {
  protected form: FormData = emptyFormData;

  validate() {
    this.$v.$touch();
    return !this.$v.$invalid;
  }

  validationMessage(field: string): string | null {
    const validation = this.$v.form[field];
    if (!validation || !validation.$error) {
      return null;
    }

    switch (field) {
      case 'fullname':
        return 'Zadejte celé jméno';

      case 'phone':
        return !validation.required ? 'Zadejte telefonní číslo' : 'Neplatný formát telefonního čísla';

      case 'addressCity':
        return 'Zadejte adresu';

      case 'addressStreet':
        return 'Zadejte adresu';

      case 'addressZip':
        return !validation.required ? 'Zadejte PSČ' : 'Neplatný formát, musíte zadat 5 číslic';

      case 'companyName':
        return 'Zadejte jméno firmy';

      case 'companyRegistrationNumber':
        return 'Zadejte platné IČ';

      case 'companyVATNumber':
        return 'Zadejte platné DIČ';

      case 'companyAddressCity':
        return 'Zadejte adresu firmy';

      case 'companyAddressStreet':
        return 'Zadejte adresu firmy';

      case 'companyAddressZip':
        return !validation.required ? 'Zadejte PSČ firmy' : 'Neplatný formát, musíte zadat 5 číslic';
    }

    return null;
  }
}
