





























import { Component, Vue, Prop } from 'vue-property-decorator';
import { EditIcon, SmileIcon, FrownIcon, InfoIcon } from 'vue-feather-icons';

@Component({
  components: {
    EditIcon,
    SmileIcon,
    FrownIcon,
    InfoIcon
  }
})
export default class DocumentsOverview extends Vue {
  @Prop({ required: true, default: null }) readonly isExpired!: boolean;
  @Prop({ required: true, default: null }) readonly expiration!: string;
  @Prop({ required: true, default: null }) readonly showEditButton!: boolean;

  get hasExpiration() {
    return this.expiration !== '';
  }

  async edit() {
    this.$emit('edit');
  }
}
