












import { Component, Vue } from 'vue-property-decorator';
import NavbarNew from '@/components/navbarNew.vue';
import FooterNew from '@/components/footerNew.vue';
import CameraHandler from '@/components/cameraHandler.vue';

@Component({
  components: {
    NavbarNew,
    FooterNew,
    CameraHandler
  }
})
export default class Default extends Vue {}
