








import { Component, Mixins } from 'vue-property-decorator';
import UserPageLayout from '@/components/user/userPageLayout.vue';
import UserDocs from '@/components/user/userDocs.vue';
import { AuthMixin } from '@/mixins/user/Auth.mixin';

@Component({
  components: {
    UserPageLayout,
    UserDocs
  }
})
export default class UserDocuments extends Mixins(AuthMixin) {}
