

















import { Component, Prop, Vue } from 'vue-property-decorator';
import Navbar from '@/components/checkout/layout/Navbar.vue';
import FooterNew from '@/components/footerNew.vue';
import CameraHandler from '@/components/cameraHandler.vue';

@Component({
  components: {
    CameraHandler,
    Navbar,
    FooterNew
  }
})
export default class PageLayout extends Vue {
  @Prop({ default: '' }) readonly title!: string;

  get hasProgress(): boolean {
    return !!this.$slots['progress'] || !!this.$scopedSlots['progress'];
  }
}
