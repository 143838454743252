
































































































import { Component, Vue } from 'vue-property-decorator';
import { PhoneIcon } from 'vue-feather-icons';
import FacebookIcon from '@/assets/icons/facebook.vue';
import InstagramIcon from '@/assets/icons/instagram.vue';
import Phone24Icon from '@/assets/icons/phone24.vue';
import store from '@/store';

@Component({
  components: {
    PhoneIcon,
    InstagramIcon,
    FacebookIcon,
    Phone24Icon
  }
})
export default class FooterNew extends Vue {
  get reservationCheckoutLoading() {
    return store.state.reservationCheckoutLoading;
  }
}
