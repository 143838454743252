import { UserDocumentFormData } from '@/store/types';

export const emptyUserDocumentFormData: UserDocumentFormData = {
  personalIdFront: null,
  personalIdBack: null,
  personalIdSelfie: null,
  drivingLicenseFront: null,
  drivingLicenseBack: null
};

export const existingUserDocumentsEditMode: { [key: string]: boolean } = {
  personalIdFront: false,
  personalIdBack: false,
  personalIdSelfie: false,
  drivingLicenseFront: false,
  drivingLicenseBack: false
};
