

























































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import UserService from '@/services/user.service';
import { ChevronLeftIcon, ChevronRightIcon } from 'vue-feather-icons';

import { CheckoutStepOneFormMixin, emptyFormData } from '@/components/screens/checkout/CheckoutStepOneForm.mixin';
import Heading from '@/components/checkout/layout/page/Heading.vue';
import TextField from '@/components/ui/form/TextField.vue';
import CheckboxField from '@/components/ui/form/CheckboxField.vue';
import Button from '@/components/ui/Button.vue';
import { ButtonColor } from '@/components/ui/Button';
import { Route } from 'vue-router';
import { HomeRoute } from '@/router/routes';
import { AuthMixin } from '@/mixins/user/Auth.mixin';
import { GtagMixin } from '@/mixins/googleAnalytics/gtag.mixin';
import { FormData } from '@/store/types';

@Component({
  components: {
    Button,
    CheckboxField,
    TextField,
    Heading,
    ChevronLeftIcon,
    ChevronRightIcon
  },
  mixins: [CheckoutStepOneFormMixin]
})
export default class UserAccount extends Mixins(CheckoutStepOneFormMixin, AuthMixin, GtagMixin) {
  @Prop() readonly isUserProfile: boolean | undefined;
  @Prop() readonly from!: Route;

  private uploading = false;
  private buttonColor: ButtonColor = 'light';

  /**
   * Reset form validations and company state specific values when changing company / person
   */
  @Watch('form.isCompany')
  onIsCompanyChange() {
    this.$v.$reset();
    if (this.form.isCompany) {
      this.form.addressCity = '';
      this.form.addressZip = '';
      this.form.addressStreet = '';
    } else {
      this.form.companyName = undefined;
      this.form.companyRegistrationNumber = undefined;
      this.form.companyVATNumber = undefined;
      this.form.companyAddressStreet = undefined;
      this.form.companyAddressCity = undefined;
      this.form.companyAddressZip = undefined;
    }
  }

  resetFormCompanyFields(form: FormData) {
    if (!form.isCompany) {
      form.companyName = undefined;
      form.companyRegistrationNumber = undefined;
      form.companyVATNumber = undefined;
      form.companyAddressStreet = undefined;
      form.companyAddressCity = undefined;
      form.companyAddressZip = undefined;
    }
  }

  changeButtonColor() {
    this.buttonColor = 'primary';
  }

  goBack(): void {
    if (this.from?.path) {
      this.$router.push({
        path: this.from.path,
        query: this.from.query,
        params: this.from.params
      });
    } else {
      this.$router.push({ name: HomeRoute });
    }
  }

  private VATNumberToUppercase(VAT: string | undefined | null) {
    return (VAT = VAT ? VAT.toUpperCase() : '');
  }

  async mounted() {
    try {
      const user = await UserService.me();
      if (!user) {
        return;
      }
      this.form = { ...emptyFormData, ...user.user };
      // Reseting potential unwanted values
      this.resetFormCompanyFields(this.form);
    } catch (e) {
      this.$toast.error('Něco se nepodařilo, zkuste to prosím později.');
    }
  }

  async proceed() {
    const success = this.validate();
    if (!success) {
      return;
    }
    try {
      this.uploading = true;
      await UserService.updateProfile(this.form);
      if (!this.isUserProfile) {
        this.$emit('changeActiveStep', 2);
        this.triggerGtagEvent('reservation-step1-submit-user-data.button', 'click');
      } else {
        this.$toast.success('Vaše údaje byly aktualizovány.');
        this.buttonColor = 'light';
      }
    } catch (e) {
      this.$toast.error('Něco se nepodařilo, zkuste to prosím později.');
    } finally {
      this.uploading = false;
    }
  }
}
