import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Default from '../views/default.vue';
import Simple from '../views/simple.vue';

import ReservationPage from '../pages/reservation.vue';
import UserProfilePage from '../pages/userProfile.vue';
import UserDocumentsPage from '../pages/userDocuments.vue';
import UserPaymentPage from '../pages/userPayment.vue';

import {
  VanSelectionRoute,
  ReservationRoute,
  PaymentRoute,
  PartnerRoute,
  MikrobusRoute,
  LogisticsRoute,
  HowItWorksRoute,
  HowItWorksEngRoute,
  ConditionsRoute,
  ConditionsFutureRoute,
  FAQRoute,
  PrivacyPolicyRoute,
  ContactsRoute,
  ReturnFormRoute,
  PickupFormRoute,
  MapRoute,
  MapVanRoute,
  PriceListRoute,
  PriceListFutureRoute,
  CookiesRoute,
  ResetPasswordRoute,
  RegistrationConfirmRoute,
  LoginRoute,
  UnavailableRoute,
  UserProfileRoute,
  UserProfileDocumentsRoute,
  MapServisRoute,
  PrivacyPolicyFutureRoute,
  UserProfilePaymentRoute,
  HomeRoute
} from '@/router/routes';
import ProfileView from '@/views/profile.vue';
import store from '@/store';
import carsService from '@/services/cars.service';
import { RENTAL } from '@/models/carType';

Vue.use(VueRouter);

const NewHomePage = () => import(/* webpackChunkName: "new-home.page" */ '../pages/newHomepage.vue'),
  VansPage = () => import(/* webpackChunkName: "vans.page" */ '../pages/vans.vue'),
  MapPage = () => import(/* webpackChunkName: "map.page" */ '../pages/map.vue'),
  PaymentPage = () => import(/* webpackChunkName: "payment.page" */ '../pages/payment.vue'),
  HowItWorksPage = () => import(/* webpackChunkName: "how-it-works.page" */ '../pages/howItWorks.vue'),
  HowItWorksEngPage = () => import(/* webpackChunkName: "how-it-works-eng.page" */ '../pages/howItWorksEng.vue'),
  PartnerPage = () => import(/* webpackChunkName: "stante-se-partnerem.page" */ '../pages/partner.vue'),
  MikrobusPage = () => import(/* webpackChunkName: "pujc-si-mikrobus.page" */ '../pages/mikrobus.vue'),
  LogisticsPage = () => import(/* webpackChunkName: "kakadoo-logistika.page" */ '../pages/logistics.vue'),
  FaqPage = () => import(/* webpackChunkName: "faq.page" */ '../pages/faq.vue'),
  ConditionsPage = () => import(/* webpackChunkName: "conditions.page" */ '../pages/conditions.vue'),
  // ConditionsFuturePage = () => import(/* webpackChunkName: "conditions-future.page" */ '../pages/conditionsFuture.vue'),
  PrivacyPage = () => import(/* webpackChunkName: "privacy.page" */ '../pages/privacy.vue'),
  // PrivacyFuturePage = () => import(/* webpackChunkName: "privacy-future.page" */ '../pages/privacyFuture.vue'),
  ContactsPage = () => import(/* webpackChunkName: "contacts.page" */ '../pages/contacts.vue'),
  PriceListPage = () => import(/* webpackChunkName: "price-list.page" */ '../pages/priceList.vue'),
  PriceListFuturePage = () => import(/* webpackChunkName: "price-list-future.page" */ '../pages/priceListFuture.vue'),
  CookiesPage = () => import(/* webpackChunkName: "cookies.page" */ '../pages/cookies.vue'),
  ReturnFormPage = () => import(/* webpackChunkName: "return-form.page" */ '../pages/returnForm.vue'),
  PickupFormPage = () => import(/* webpackChunkName: "pickup-form.page" */ '../pages/pickupForm.vue'),
  NotFoundPage = () => import(/* webpackChunkName: "not-found.page" */ '../pages/page404.vue'),
  ResetPasswordPage = () => import(/* webpackChunkName: "reset-password.page" */ '../pages/resetPassword.vue'),
  RegistrationConfirmPage = () =>
    import(/* webpackChunkName: "registration-confirm.page" */ '../pages/registrationConfirm.vue'),
  LoginPage = () => import(/* webpackChunkName: "registration-confirm.page" */ '../pages/login.vue'),
  UnavailablePage = () => import(/* webpackChunkName: "unavailable.page" */ '../pages/unavailable.vue');

const getVanSelectionRoutes = () => {
  return store.state.eligiblePrimaryParams.map((param) => {
    return {
      path: '/',
      component: Default,
      children: [
        {
          path: `/${param}/:secondaryParam?`,
          name: VanSelectionRoute + param,
          component: VansPage
        }
      ]
    };
  });
};

const routes: Array<RouteConfig> = [
  {
    path: '/mapa/servis',
    name: MapServisRoute,
    component: MapPage
  },
  {
    path: '/',
    component: Default,
    children: [
      {
        path: '/',
        name: HomeRoute,
        component: NewHomePage
      },
      {
        path: '/mapa/:reservationId',
        name: MapRoute,
        component: MapPage
      },
      {
        path: '/mapa/dodavka/:carId',
        name: MapVanRoute,
        component: MapPage
      },
      {
        path: '/pujc-si-mikrobus',
        name: MikrobusRoute,
        component: MikrobusPage
      },
      {
        path: '/kakadoo-logistika',
        name: LogisticsRoute,
        component: LogisticsPage
      },
      {
        path: '/potvrzeni/:reservationId/:blowfish?',
        name: PaymentRoute,
        component: PaymentPage
      },
      {
        path: '/stante-se-partnerem',
        name: PartnerRoute,
        component: PartnerPage
      },
      {
        path: '/jak-to-funguje',
        name: HowItWorksRoute,
        component: HowItWorksPage
      },
      {
        path: '/how-it-works',
        name: HowItWorksEngRoute,
        component: HowItWorksEngPage
      },
      {
        path: '/caste-dotazy',
        name: FAQRoute,
        component: FaqPage
      },
      {
        path: '/podminky',
        name: ConditionsRoute,
        component: ConditionsPage
      },
      {
        path: '/nove-podminky',
        name: ConditionsFutureRoute,
        // component: ConditionsFuturePage
        redirect: { name: ConditionsRoute }
      },
      {
        path: '/ochrana-osobnich-udaju',
        name: PrivacyPolicyRoute,
        component: PrivacyPage
      },
      {
        path: '/nova-ochrana-osobnich-udaju',
        name: PrivacyPolicyFutureRoute,
        // component: PrivacyFuturePage
        redirect: { name: PrivacyPolicyRoute }
      },
      {
        path: '/kontakty',
        name: ContactsRoute,
        component: ContactsPage
      },
      {
        path: '/o-nas',
        redirect: { name: ContactsRoute }
      },
      {
        path: '/kontakt',
        redirect: { name: ContactsRoute }
      },
      {
        path: '/cookies',
        name: CookiesRoute,
        component: CookiesPage
      },
      {
        path: '/cenik',
        name: PriceListRoute,
        component: PriceListPage
      },
      {
        path: '/novy-cenik',
        name: PriceListFutureRoute,
        component: PriceListFuturePage,
        redirect: { name: PriceListRoute }
      }
    ]
  },
  {
    path: '/',
    component: Simple,
    children: [
      {
        path: '/vraceni',
        name: ReturnFormRoute,
        component: ReturnFormPage
      }
    ]
  },
  {
    path: '/',
    component: Simple,
    children: [
      {
        path: '/vyzvednuti',
        name: PickupFormRoute,
        component: PickupFormPage
      }
    ]
  },
  {
    path: '/rezervace',
    name: ReservationRoute,
    component: ReservationPage
  },
  {
    path: '/reset-hesla/:resetPasswordToken',
    name: ResetPasswordRoute,
    component: ResetPasswordPage
  },
  {
    path: '/potvrzeni-registrace/:registerToken',
    name: RegistrationConfirmRoute,
    component: RegistrationConfirmPage
  },
  {
    path: '/login',
    name: LoginRoute,
    component: LoginPage
  },
  {
    path: '/blokace',
    name: UnavailableRoute,
    component: UnavailablePage
  },
  {
    path: '/',
    component: ProfileView,
    children: [
      {
        path: '/profil',
        name: UserProfileRoute,
        component: UserProfilePage
      },
      {
        path: '/profil/doklady',
        name: UserProfileDocumentsRoute,
        component: UserDocumentsPage
      },
      {
        path: '/profil/platba',
        name: UserProfilePaymentRoute,
        component: UserPaymentPage
      }
    ]
  },
  ...getVanSelectionRoutes(),
  {
    path: '*',
    name: 'not-found',
    component: NotFoundPage
  }
];

export const createRouter = async (): Promise<VueRouter> => {
  const availableFilters = await carsService.getAvailableFilters();
  // set all loaded path slugs and the rental slugs as valid primary params
  store.dispatch('setEligiblePrimaryParams', [
    ...availableFilters.vehicleFilters.map((filter) => filter.parentPathSlug),
    RENTAL
  ]);
  store.dispatch('setVehicleFilters', availableFilters.vehicleFilters);
  store.dispatch('setParkingFilters', availableFilters.parkingFilters);
  getVanSelectionRoutes().forEach((route) => {
    // make sure to not push duplicate routes
    if (!routes.some((r) => r.children && r.children[0].path === route.children[0].path)) {
      routes.push(route);
    }
  });
  return new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        // Restore scroll position when going back
        return {
          behavior: 'smooth',
          x: savedPosition.x,
          y: savedPosition.y
        };
      } else {
        // Scroll to the top on new routes
        return { x: 0, y: 0 };
      }
    }
  });
};
