

















































import { Component, Mixins } from 'vue-property-decorator';
import Button from '@/components/ui/Button.vue';
import { XIcon, PlusIcon, AlertCircleIcon } from 'vue-feather-icons';
import Loading from '@/components/ui/Loading.vue';
import { StripeConfirmSetupIntentError, StripeMixin } from '@/mixins/stripe';
import { StripeElements } from '@stripe/stripe-js';
import Alert from '@/components/ui/Alert.vue';

@Component({
  components: {
    Loading,
    XIcon,
    Button,
    PlusIcon,
    Alert,
    AlertCircleIcon
  }
})
export default class AddPaymentMethodModal extends Mixins(StripeMixin) {
  private loadingPaymentForm = true;
  private loadingSaveCard = false;
  private elements?: StripeElements;

  mounted() {
    this.loadPaymentForm();
  }

  async loadPaymentForm() {
    try {
      this.loadingPaymentForm = true;
      this.elements = await this.setupPaymentCardForm('#add-payment-method-modal-payment-form');
    } catch (err) {
      console.error('Error happened while mounting payment form', err);
    } finally {
      this.loadingPaymentForm = false;
    }
  }

  async saveCard() {
    if (!this.elements) {
      return;
    }
    try {
      this.loadingSaveCard = true;
      await this.confirmCardSetup(this.elements);
      this.$emit('reload');
      this.close();
    } catch (err) {
      if (err instanceof StripeConfirmSetupIntentError) {
        console.error('Expected error happened while setting up card', err);
        this.$toast.error(this.parseConfirmSetupIntentErrorMessage(err));
      } else {
        this.$toast.error('Něco se pokazilo, opakujte akci prosím později.');
        console.error('Error happened while saving card', err);
      }
    } finally {
      this.loadingSaveCard = false;
    }
  }

  close() {
    this.$emit('close');
  }
}
