



















import { Component, Vue, Prop } from 'vue-property-decorator';
import { AlertType } from '@/components/ui/Alert';

@Component
export default class Alert extends Vue {
  @Prop({ required: false, default: null }) readonly title!: string | null;
  @Prop({ required: true, default: '' }) readonly message!: string;
  @Prop({ required: true, default: '' }) readonly type!: AlertType;

  hasIcon(): boolean {
    return !!this.$slots['icon'];
  }
}
