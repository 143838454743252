




















































import { Component, Watch, Vue } from 'vue-property-decorator';
import Button from '@/components/ui/Button.vue';
import { XIcon, PlusIcon } from 'vue-feather-icons';
import userService from '@/services/user.service';
import { PaymentInstrument } from '@/models/paymentInstrument';
import Loading from '@/components/ui/Loading.vue';
import AddPaymentMethodModal from '@/components/user/payment/AddPaymentMethodModal.vue';

@Component({
  components: {
    AddPaymentMethodModal,
    Loading,
    XIcon,
    Button,
    PlusIcon
  }
})
export default class CheckoutPayment extends Vue {
  private loading = true;
  private cardModalOpened = false;

  private selectedPaymentInstrument: PaymentInstrument | null = null;

  async mounted() {
    await this.loadPaymentInstruments();
  }

  async loadPaymentInstruments() {
    try {
      this.loading = true;
      const paymentInstruments = await userService.getPaymentInstruments();
      this.selectedPaymentInstrument = paymentInstruments[0] ?? null;
    } catch (err) {
      console.error('Error happened during loading payment instruments', err);
      this.selectedPaymentInstrument = null;
    } finally {
      this.loading = false;
    }
  }

  @Watch('selectedPaymentInstrument', { immediate: true, deep: true })
  onChangeStoredPaymentInstrument(storedPaymentInstrument: PaymentInstrument | null) {
    this.$emit('changePaymentInstrument', storedPaymentInstrument);
  }

  closeCardModal() {
    this.cardModalOpened = false;
  }

  async addNewCard() {
    this.cardModalOpened = true;
  }
}
