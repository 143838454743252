














import { Component, Prop, Vue } from 'vue-property-decorator';

import { CheckoutStep } from '@/services/checkout.service';

import Step from './Step.vue';

@Component({
  components: { Step }
})
export default class ProgressBar extends Vue {
  @Prop({ required: true }) readonly step!: CheckoutStep;

  get isContactInfoStep(): boolean {
    return this.step === CheckoutStep.CONTACT_INFO;
  }

  get isIdentityVerificationStep(): boolean {
    return this.step === CheckoutStep.IDENTITY_VERIFICATION;
  }

  get isPaymentStep(): boolean {
    return this.step === CheckoutStep.PAYMENT_CONFIRMATION;
  }
}
