<template>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.38998 16V8C3.38998 5.23858 5.62856 3 8.38998 3H16.39C19.1514 3 21.39 5.23858 21.39 8V16C21.39 18.7614 19.1514 21 16.39 21H8.38998C5.62856 21 3.38998 18.7614 3.38998 16Z"
      stroke="white"
      stroke-width="1.5"
    />
    <path
      d="M17.89 6.51L17.9 6.49889"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.39 16C14.5991 16 16.39 14.2091 16.39 12C16.39 9.79086 14.5991 8 12.39 8C10.1808 8 8.38998 9.79086 8.38998 12C8.38998 14.2091 10.1808 16 12.39 16Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'InstagramIcon'
};
</script>
