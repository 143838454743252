





















































































































import { Component, Mixins, Watch } from 'vue-property-decorator';
import UserService from '@/services/user.service';
import { LoginRoute } from '@/router/routes';
import { UserIcon } from 'vue-feather-icons';
import store from '@/store';
import { RENTAL } from '@/models/carType';
import moment from 'moment';
import { AuthMixin } from '@/mixins/user/Auth.mixin';

@Component({
  components: {
    UserIcon
  }
})
export default class NavbarNew extends Mixins(AuthMixin) {
  public menuVisible = false;
  public isNavOpened = false;

  public scrollToTop() {
    window.scrollTo(0, 0);
  }
  public toggleNav() {
    this.isNavOpened = !this.isNavOpened;
  }

  get isIn2023() {
    return moment().year() === 2023;
  }

  get toggledNav() {
    return this.isNavOpened ? 'nav-opened' : 'nav-closed';
  }

  get isHome() {
    return this.$route.name === 'home';
  }

  public async login() {
    if (this.loggedUser) {
      try {
        if (this.$gAuth.isAuthorized) {
          await this.$gAuth.signOut();
        }
        await UserService.logout();
        await store.dispatch('fetchLoggedUser');
        this.$toast.success('A jste odhlášen!');
      } catch (e) {
        this.$toast.error((e as Error).message);
      }
    } else {
      this.$router.push({ name: LoginRoute, query: { login: '1' } });
    }
  }

  public getRoute() {
    return `/${RENTAL}?from=${moment().format('YYYY-MM-DD')}&to=${moment().format('YYYY-MM-DD')}`;
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.menuVisible = false;
  }
}
